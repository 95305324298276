import React from "react";
import menuItemStub from "../../assets/img/menu-item-stub.svg";
import menuCategoryStub from "../../assets/img/menu-category-stub.svg";
import "./MenuItem.css";
import Img from "../Image";

const MenuItemType = {
  Category: "category",
  Item: "item",
};

const MenuItem = ({ name, image, type }) => {
  const stub = type === MenuItemType.Category ? menuCategoryStub : menuItemStub;

  return (
    <div className="menu-item">
      <div
        className="menu-item_image-wrapper blur blur-light mb-2"
        style={{
          backgroundImage: `url(${stub})`, 
        }}
      >
		  {image && <Img className="image-cover-wide" src={`${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${image}&width=500&height=500`} alt={name} />}
	  </div>

      <p className={`menu-item-name ${type === MenuItemType.Category ? "text-ellipsis" : ""}`}>{name}</p>
    </div>
  );
};

export default MenuItem;
