import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import Question from "../../components/Question/Question";
import Advise from "../../components/Advise/Advise";
import MakePhoto from "../../components/MakePhoto/MakePhoto";
import UploadPhoto from "../../components/UploadPhoto/UploadPhoto";
import Contacts from "../../components/Contacts/Contacts";
import Final from "../../components/Final/Final";
import jsonToFormData from "json-form-data";
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";
import BackButton from "../../components/BackButton";
import { sendingError } from "../../assets/constants";
import "./Nps.css";

const Nps = ({
  store: {
    getLocalized,
    restaurant: { id },
  },
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  const [estimated, setEstimated] = useState(Array.from({ length: 4 }, (v, i) => ({ questionId: i + 1, answerValue: undefined })));

  const [available, setAvailable] = useState(false);

  const [imgSrc, setImgSrc] = useState(null);

  const [credentials, setCredentials] = useState({ name: "", phone: "" });

  const [snackbar, setSnackbar] = useState({ alertError: "", snackbarOpen: false });

  const handleCredentials = ({ target: { name, value } }) => {
    if (name === "phone") {
      value = value.replace(/[^0-9+]/g, "");
    }
    setCredentials({ ...credentials, [name]: value });
  };

  const setMark = (markObject) => {
    const index = estimated.findIndex((item) => item.questionId === markObject.questionId);
    estimated.splice(index, 1, markObject);
    setEstimated([...estimated]);
  };

  const nextPage = () => setPageNumber((v) => v + 1);

  const goToPage = (page) => setPageNumber(page);

  const handleSend = () => {
    const data = jsonToFormData({ Answers: estimated });
    data.append("RestaurantId", id);
    data.append("Name", credentials.name);
    data.append("Phone", credentials.phone);
    data.append("Photo", imgSrc);

    fetch(`${process.env.REACT_APP_API}/api/NPS/UpsertNps/`, {
      method: "POST",
      headers: { "api-version": "2.0" },
      body: data,
    })
      .then((res) => {
        if (res.ok) {
          setPageNumber(7);
        } else {
          handleSnackbarOpen(sendingError);
        }
      })
      .catch(() => handleSnackbarOpen(sendingError));
    // .catch(() => setPageNumber(7))
  };

  const handleSnackbarClose = () => {
    setSnackbar({ alertError: "", snackbarOpen: false });
  };

  const handleSnackbarOpen = (message) => {
    setSnackbar({ alertError: getLocalized(message), snackbarOpen: true });
  };

  let page;
  switch (pageNumber) {
    case 1:
    case 2:
    case 3:
      page = <Question key={pageNumber} setEstimated={setMark} questionId={pageNumber} nextPage={nextPage} />;
      break;
    case 4:
      page = <Advise setEstimated={setMark} goToPage={goToPage} setAvailable={setAvailable} />;
      break;
    case 5:
      if (available) {
        page = <MakePhoto nextPage={nextPage} imgSrc={imgSrc} setImgSrc={setImgSrc} handleSnackbarOpen={handleSnackbarOpen} />;
        break;
      } else {
        page = <UploadPhoto nextPage={nextPage} imgSrc={imgSrc} setImgSrc={setImgSrc} />;
        break;
      }
    case 6:
      page = <Contacts handleCredentials={handleCredentials} credentials={credentials} goToPage={goToPage} handleSend={handleSend} />;
      break;
    case 7:
      page = <Final />;
      break;

    default:
      page = <Question setEstimated={setMark} questionId={0} nextPage={nextPage} />;
  }

  return (
    <>
      <div className="page page-nps">
        <div className="back-holder">
          <BackButton inverse/>
        </div>

        {page}
      </div>
      <ErrorSnackbar open={snackbar.snackbarOpen} message={snackbar.alertError} handleSnackbarClose={handleSnackbarClose} />
    </>
  );
};

export default inject("store")(observer(Nps));
