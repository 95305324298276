import React,  { useState, useEffect } from 'react'
import {inject, observer} from "mobx-react";
import Image from "./Image/Image";
import {smiles, labels, blancActive} from "../../assets/constants";
import Header from "../Header/Header";
import CSSTransition from "react-transition-group/CSSTransition";
import MoneyBag from "../MoneyBag/MoneyBag";
import './Question.css';

const Question = ({store: {getLocalized}, setEstimated, questionId, nextPage}) => {
    const [isActive, setIsActive] = useState(blancActive);
    const [disabled, setDisabled] = useState(true);
    const [start, setStart] = useState(false);

    useEffect(() => {
        setStart(true);
        return () => setStart(false);
    }, []);


    const onSelect = (index) => {
        setIsActive(Array.from({length: 5}, (value, i) => index === i ));
        setEstimated({questionId, answerValue: smiles[index].label});
        setDisabled(false);
    };

    const smileList = smiles.map((item, index) => (
        <Image
        key={item.label}
        item={item}
        active={isActive[index]}
        index={index}
        onSelect={onSelect}
        />
    ));

    const toNextPage = () => {
        setIsActive(blancActive);
        setDisabled(true);
        nextPage();
    };
    const label = getLocalized(labels.find(item => item.id === questionId).label);

    return(
        <>
            <Header>
                {getLocalized('pleaseLeaveYourFeedback')}
                <br/>
                {getLocalized('aboutOurRestaurant')}                
            </Header>
            <div className="answer-container background-primary">
                    <CSSTransition in={start} timeout={800} classNames='animate'>
                        <div>
                            <p className='estimate'>{label}</p>
                            <div className="smile-container">
                                {smileList}
                            </div>
                        </div>
                    </CSSTransition>
                    <button
                        className='btn btn-primary btn-xlg btn-rounded mb-4 question-button'
                        disabled={disabled}
                        onClick={toNextPage}
                    >
                        {getLocalized('next')}
                    </button>
            </div>
        </>
    )
};

export default inject('store')(observer(Question));


