import React from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "./MenuItems.css";
import RestaurantHeader from "../../components/RestaurantHeader/RestaurantHeader";
import MenuItem from "../../components/MenuItem/MenuItem";

const MenuItems = ({ store: { menuCategories, wineCategories } }) => {
  const params = new URL(window.location.href).searchParams;
  const menuCategoryId = +params.get("menuCategoryId");
  const wineCategoryId = +params.get("wineCategoryId");
  const page = menuCategoryId ? 'dish' : 'alcohol'

  const categoryId = wineCategoryId || menuCategoryId;

  const category = menuCategoryId ?
    menuCategories.find((item) => item.id === menuCategoryId):
    wineCategories.find((item) => item.id === wineCategoryId);

  const items = category ? category.menuItems || category.alcoholicItems : [];
  const title = category ? category.name : null;
  const list = items.map((item) => {
    return (
      <div key={`${menuCategoryId ? 'dish' : 'alcohol'}-${item.id}`} className="col-12 mb-4">
        <Link to={`/${page}?categoryId=${categoryId}&itemId=${item.id}`}>
          <MenuItem name={item.name} image={item.images[0] && item.images[0].fileName} type="item"/>
        </Link>
      </div>
    );
  });
  return (
    <div className="page p-4">
      <RestaurantHeader />
      <h3 className="mb-2">{title}</h3>

      <div className="row">{list}</div>
    </div>
  );
};

export default inject("store")(observer(MenuItems));
