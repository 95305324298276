import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/css';
import {observer, inject} from 'mobx-react';
import Portal from "./Portal";
import closeIcon from '../assets/img/close-icon-new.svg'
import arrowLeft from '../assets/img/arrow-left-new.svg'
import arrowRight from '../assets/img/arrow-right-new.svg'

export const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/quicktime'];

const ImagesPreviewModal = (props) => {
    const modal = React.createRef();

    const handleClickOutside = (e) => {
        if (modal.current && !modal.current.contains(e.target)) {
            props.store.toggleModal();
        }
    };

    const {
        images,
        name,
        currentImageIndex,
        toggleModal,
        showPreviousImage,
        showNextImage,
    } = props.store;

    const imagesCount = images.length;
    const isSingleImage = imagesCount === 1;
    let isVideo = false;

    if (images.length) {
        isVideo = ALLOWED_VIDEO_TYPES.includes(
            images[currentImageIndex].mimeType,
        );
    }

    return (
        <Portal>
            <Background role="button" onClick={handleClickOutside}>
                <PreviewModal ref={modal} boxShadow={isVideo ? 'none' : null}>
                    <ImagePreviewContainer>
                        {isVideo ? (
                            <VideoContainer>
                                <video
                                    style={{maxHeight: 400, width: '100%'}}
                                    disablePictureInPicture
                                    controlsList="nodownload"
                                    key={images[currentImageIndex].fileName}
                                    controls={true}
                                >
                                    <source
                                        src={
                                            `${process.env.REACT_APP_API}/uploads/GetPublic?fileName=${images[currentImageIndex].fileName}`
                                        }
                                    />
                                </video>
                                <LabelWrapper>
                                    <NameWrapper>{name}</NameWrapper>
                                    <ImagePreviewCounter>
                                        {currentImageIndex + 1} / {imagesCount}
                                    </ImagePreviewCounter>
                                    <ButtonCancel onClick={toggleModal}>
                                        <img src={closeIcon} alt=""/>
                                    </ButtonCancel>
                                </LabelWrapper>
                                {isSingleImage ? null : (
                                    <ControlButtonsWrapper>
                                        <ImageControlButton onClick={showPreviousImage}>
                                            <IconCircleWrapper>
                                                <IconCircle>
                                                    <img src={arrowLeft} alt=""/>
                                                </IconCircle>
                                            </IconCircleWrapper>
                                        </ImageControlButton>
                                        <ImageControlButton onClick={showNextImage}>
                                            <IconCircleWrapper>
                                                <IconCircle>
                                                    <img src={arrowRight} alt=""/>
                                                </IconCircle>
                                            </IconCircleWrapper>
                                        </ImageControlButton>
                                    </ControlButtonsWrapper>
                                )}
                            </VideoContainer>
                        ) : (
                            <>
                                <LabelWrapper>
                                    <NameWrapper>{name}</NameWrapper>
                                    <ImagePreviewCounter>
                                        {currentImageIndex + 1} / {imagesCount}
                                    </ImagePreviewCounter>
                                    <ButtonCancel onClick={toggleModal}>
                                        <img src={closeIcon} alt=""/>
                                    </ButtonCancel>
                                </LabelWrapper>
                                {isSingleImage ? null : (
                                    <ControlButtonsWrapper>
                                        <ImageControlButton onClick={showPreviousImage}>
                                            <IconCircleWrapper>
                                                <IconCircle>
                                                    <img src={arrowLeft} alt=""/>
                                                </IconCircle>
                                            </IconCircleWrapper>
                                        </ImageControlButton>
                                        <ImageControlButton onClick={showNextImage}>
                                            <IconCircleWrapper>
                                                <IconCircle>
                                                    <img src={arrowRight} alt=""/>
                                                </IconCircle>
                                            </IconCircleWrapper>
                                        </ImageControlButton>
                                    </ControlButtonsWrapper>
                                )}
                                <img
                                    css={css`
                        max-height: 600px;
                        width: 100%;
                      `}
                                    src={
                                        images[currentImageIndex].fileName.startsWith('blob')
                                            ? images[currentImageIndex].fileName
                                            : `${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${
                                                images[currentImageIndex].fileName
                                            }&width=400`
                                    }
                                    alt=''
                                />
                            </>
                        )}
                    </ImagePreviewContainer>
                </PreviewModal>
            </Background>
        </Portal>
    );
}

const Background = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;

  :before {
    content: '';
    position: absolute;
    z-index: -1;
    background: #000000;
    opacity: 0.4;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

const PreviewModal = styled.div`
  box-shadow: ${({boxShadow}) =>
    boxShadow ? boxShadow : '0px 10px 60px rgba(0, 0, 0, 0.27)'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 14px;
  position: relative;
`;

const ImagePreviewContainer = styled.div`
  flex: 1;
  position: relative;
`;

const ControlButtonsWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LabelWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
  height: 50px;
  font-family: Rubik;
  background: #000000;
  opacity: 0.65;
  color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
`;

const NameWrapper = styled.div`
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonWithoutStyles = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  text-align: center;
  padding: 0;

  :focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const ButtonCancel = styled(ButtonWithoutStyles)`
  font-size: 12px;
  color: #ffffff;
  font-family: 'Work Sans', sans-serif;
`;

const ImagePreviewCounter = styled.div`
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  display: block;
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #000000;
  opacity: 0.65;
  border-radius: 50%;
`;

const IconCircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: transparent;
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ImageControlButton = styled(ButtonWithoutStyles)``;


export default inject('store')(observer(ImagesPreviewModal));
