export function formatNumber(number, locale = 'en') {
	if (!number) return number;
	const devider = locale === 'en' ? ',' : ' ';
	const [integer, decimal] = number.toString().split('.');
	const reversed = integer.split('').reverse();

	const separated = [];
	reversed.forEach((item, index) => {
        if (index !== 0 && index % 3 === 0) {
            separated.push(devider);
        }
        separated.push(item);
	});
	
	return separated.reverse().join('') + (decimal ? `.${decimal}` : '') 
}