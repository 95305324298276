import React from "react";
import { inject, observer } from "mobx-react";
import { wineAttributes, beerAttributes, cocktailAttributes } from "../../assets/constants";
import './WineItemAttributes.css';

const WineItemAttributes = ({ store: { getLocalized }, attributes }) => {
  const attributesSettings = attributes.type === 1 ? wineAttributes : attributes.type === 2 ? beerAttributes : attributes.type === 3 ? cocktailAttributes : []
  const list = attributesSettings.map((item, index) => {
    const { label, icon, values, name } = item;
    return (
      <div className="wine-attribute d-flex align-items-center py-3" key={label}>
        {icon && <img className="mr-3" src={icon} alt="" />}
        <div className="d-flex flex-grow-1 justify-content-between">
          <p className="wine-attribute-label text-gray-60">
            {name ? getLocalized(name) : getLocalized(label)}
          </p>
          <p className="wine-attribute-value text-primary">{values ? getLocalized(values[attributes[label] - 1]): typeof attributes[label] === 'object' && !!attributes[label] ? attributes[label].name : attributes[label]}{label === "alcoholLevel" || label === "ibu" || label === "gravity" ? "%" : ""}</p>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="label-field background-secondary p-3 mx-n3">{getLocalized("additionalInfo")}</div>
      <div className="d-flex flex-column mb-3">{list}</div>
    </>
  );
};

export default inject("store")(observer(WineItemAttributes));
