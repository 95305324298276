import React from "react";
import { inject, observer } from "mobx-react";
import MenuItemTile from "../MenuItemTile/MenuItemTile";


const MenuItemPairs = ({
  store: {
    getLocalized
  },
  items,
  onClick
}) => {
	const pairsList = items && items.map((item, index) => {
	  const {
        images,
        name,
        id,
        type,
        menuCategory: { id: menuCategoryId },
      } = item;
      const fileName = images[0] && images[0].fileName;
      const handleClick= () => {
        onClick(menuCategoryId, id, type)
      }
      return (
        <MenuItemTile
          key={id}
          className={`mb-2 ${(index + 1) % 3 === 0  ? '': 'mr-4'}`}
          fileName={fileName}
          name={name}
          onClick={handleClick}
        />
      );
  });
      
  return  (items && !!items.length) ? (
		<>
		  <div className="label-field background-secondary p-3 mx-n3 mb-3">{getLocalized("goesGreatWith")}</div>
		  <div className="d-flex flex-wrap mb-4">{pairsList}</div>
		</>
	  ) : null;
};

export default inject("store")(observer(MenuItemPairs));
