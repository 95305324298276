import React from 'react'
import { inject, observer } from 'mobx-react'
import './MenuItemSizes.css'
import { formatNumber } from '../../helpers/formatNumber'
import { formatPrice } from '../../helpers/formatPrice'
import { getPluralForm } from '../../helpers/pluralForm'

export const menuUnitsType = {
  1: 'ml',
  2: 'g',
  3: 'pcs',
  4: ['oz', 'ozTwo', 'ozFive'],
  5: ['lb', 'lbTwo', 'lbFive'],
}

// const wineUnitsType = {
//   1: 'ml',
//   2: ['glass', 'glassTwo', 'glassFive'],
//   3: ['bottle', 'bottleTwo', 'bottleFive'],
//   4: ['oz', 'ozTwo', 'ozFive'],
// }

/* const correctAnswersVariants = [
	'correctAnswer',
	'correctAnswersTwo',
	'correctAnswersFive',
  ]; */

const MenuItemSizes = ({
  store: { getLocalized, language },
  sizes,
  currency,
  type = 'menu',
}) => {
  return (
    sizes &&
    sizes.map((item) => {
      const {
        name,
        price,
        amountStr,
        amount,
        measurementUnitsType,
        calories,
      } = item
      const resultAmount = type === 'menu' ? amount : amountStr
      return (
        <div key={item.id} className="sizes">
          {sizes.length > 1 && <p className="size-label mb-2">{name}</p>}
          {(!!price || !!resultAmount || !!calories) && (
            <div className="d-flex align-items-center mb-4">
              {!!price && (
                <div className="size-tab background-secondary">
                  {formatPrice(
                    formatNumber(price, language),
                    currency.toUpperCase(),
                  )}
                </div>
              )}
              {!!resultAmount && (
                <div className="size-tab background-secondary">
                  {`${resultAmount} ${getLocalized(
                    getPluralForm(
                      resultAmount,
                      menuUnitsType[measurementUnitsType],
                    ),
                  )}`}
                </div>
              )}
              {!!calories && (
                <div className="size-tab background-secondary">
                  {`${calories} ${getLocalized('kcal')}`}
                </div>
              )}
            </div>
          )}
        </div>
      )
    })
  )
}

export default inject('store')(observer(MenuItemSizes))
