import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import logoBlack from "../../assets/img/logo-black.svg";
import logoWhite from "../../assets/img/logo-white.svg";
import LanguageSelect from "../../components/LanguageSelect";
import SurpriseMeButton from "../../components/SurpriseMeButton/SurpriseMeButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./Home.css";
import RestaurantHeader from "../../components/RestaurantHeader/RestaurantHeader";
import {QrCodePageTheme} from "../../store/store";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Home = ({
  store: { getLocalized, restaurant: {qrCodePageTheme}, snackbarOpen, setSnackbarOpen, spotCheckers, menuCategories, linkError },
  history,
}) => {
  useEffect(() => {
    if (linkError) {
      history.push("/404");
    }
  }, [linkError]);
  const logo = qrCodePageTheme === QrCodePageTheme.Dark ? logoWhite : logoBlack;
  const hasSpotCheckers = !!spotCheckers.length;
  const hasMenuItems = menuCategories.some((item) => !!item.menuItems.length);
  return (
    <div className="page p-4">
    <div className="d-flex justify-content-end flex-shrink-0">
      <LanguageSelect />
    </div>
      <RestaurantHeader hideBack/>
      <div className="btn-wrapper flex-grow-1 flex-shrink-0">

        {hasMenuItems && (
          <>
            <Link className="mb-3" to="/meal-survey">
              <div className="btn btn-primary">{getLocalized("suggestMeal")} <span role="img" aria-label="roket">🚀</span></div>
            </Link>
            <div className="separator mb-3 mx-3"></div>
          </>
        )}

        {hasSpotCheckers && (
          <Link className="mb-3" to="/spot-checkers">
            <div className="btn btn-default">{getLocalized("checkOurSanitization")} <span role="img" aria-label="hot">🔥</span></div>
          </Link>
        )}
        {hasMenuItems && (
          <Link className="mb-3" to="/menu">
            <div className="btn btn-default">{getLocalized("viewMenu")} <span role="img" aria-label="menu">🍔</span></div>
          </Link>
        )}

        {hasMenuItems && <SurpriseMeButton />}

        <Link className="mb-3" to="/nps">
          <div className="btn btn-default"><span role="img" aria-label="prize">😃</span>{getLocalized("leaveFeedback")}<span role="img" aria-label="prize">😃</span></div>
        </Link>
      </div>
      <footer className="footer p-1 mt-4 d-flex justify-content-center flex-shrink-0">
        <img src={logo} alt="" />
      </footer>
      {snackbarOpen && (
        <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="error">
            {getLocalized("smthGoneWrong")}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default inject("store")(observer(Home));
