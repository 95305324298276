import { Component } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("portal-container");

export default class Portal extends Component {

    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount() {
        if (modalRoot) {
            modalRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (modalRoot) {
            modalRoot.removeChild(this.el);
        }
    }
    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}
