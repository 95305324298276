export const translate3d = (x, y, index = 0) => {
	index = index > 3 ? 3 : index;
	const translate = `translate3d(${x}px, ${y - index * 15}px, 0px) rotate(${x/40}deg) scale(${1 - index*0.04})`
	return {
		msTransform: translate,
		WebkitTransform: translate,
		transform: translate
	}
}

export const DIRECTIONS = [ 'Right', 'Left', 'Top', 'Bottom' ]