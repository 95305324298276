import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import RestaurantHeader from "../../components/RestaurantHeader/RestaurantHeader";
import Survey, { SurveySimpleQuestion, SurveyMultiSelectQuestion } from "../../components/Survey/Survey";
import MealSurveyFinished from "./MealSurveyFinished";
import { SurveyQuestionType } from "../../store/surveyStore";
import "./MealSurvey.css";
import Img from "../../components/Image";
import menuItemStub from "../../assets/img/menu-item-stub.svg";

const MealSurvey = ({
  store: {
    surveyStore: {
      questions,
      suggestions: { items: suggestions, note },
      answers,
      isCompleted,
      activeSuggestionIndex,
      activeQuestionIndex,
      setAnswers,
      setQuestionAnswer,
      setSuggestionsActiveIndex,
    },
    getLocalized,
  },
  history,
}) => {
  const answersQuery = new URL(window.location.href).searchParams.get("answers");
  const activeIndex = +new URL(window.location.href).searchParams.get("activeIndex");

  useEffect(() => {
    if (activeIndex) {
      setSuggestionsActiveIndex(activeIndex)
    }
  }, [activeIndex]);

  useEffect(() => {
    if (answersQuery) {
      try {
        const parsedAnswers = JSON.parse(window.atob(answersQuery));
        setAnswers(parsedAnswers);
      } catch (e) {}
    } else {
      setAnswers();
    }
  }, [answersQuery]);

  useEffect(() => {
    if (isCompleted && !answersQuery) {
      history.push(`/meal-survey?answers=${window.btoa(JSON.stringify(answers))}`);
    }
  }, [isCompleted]);

  const onQuestionAnswer = (index, data) => {
    setQuestionAnswer(data);
  };

  const onDishAnswer = (index, data) => {
    if (data) {
      navigateToItem();
    } else {
      history.replace(window.location.pathname + `?answers=${answersQuery}&activeIndex=${activeSuggestionIndex + 1}`);
    }
  };

  const navigateToItem = () => {
    const categoryId = suggestions[activeSuggestionIndex].menuCategory.id;
    const itemId = suggestions[activeSuggestionIndex].id;
    history.push(`/dish/?categoryId=${categoryId}&itemId=${itemId}&activeIndex=${suggestions.length - 1 > activeSuggestionIndex ? activeSuggestionIndex + 1 : -1}`);
  }
  return (
    <div className="page p-4">
      <div className="mb-2">
        <RestaurantHeader />
      </div>
      {!isCompleted ? (
        <>
          <Survey onAnswer={onQuestionAnswer} key={"questions"} activeQuestion={activeQuestionIndex}>
            {questions.map((q) => {
              switch (q.type) {
                case SurveyQuestionType.YesNo:
                  return (
                    <SurveySimpleQuestion id={q.responseField} key={q.responseField}>
                      <div className="meal-question">
                        <div className="question-image-wrapper">
                          <Img className="image-contain" src={q.image} />
                        </div>
                        <h4 className="question-title d-flex flex-column justify-content-center">{q.text}</h4>
                      </div>
                    </SurveySimpleQuestion>
                  );
                case SurveyQuestionType.Multiselect:
                  return (
                    <SurveyMultiSelectQuestion
                      id={q.responseField}
                      key={q.responseField}
                      options={q.options.map((opt) => (
                        <div className="meal-allergen d-flex align-items-center" key={opt.id}>
                          <img
                            src={`${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${opt.image}&width=100&height=100`}
                            alt={opt.name}
                          />
                          <p>{opt.name}</p>
                        </div>
                      ))}
                    >
                      <h4 className="question-title d-flex flex-column justify-content-center mb-3">{q.text}</h4>
                    </SurveyMultiSelectQuestion>
                  );
                default:
                  return null;
              }
            })}
          </Survey>
          {questions[activeQuestionIndex].type === SurveyQuestionType.YesNo && <p className="survey-footer text-gray-80">{getLocalized('swipe')}</p>}
        </>
      ) : activeSuggestionIndex < suggestions.length ? (
        <>
          {note ? (
            <h4 className="mt-n4 mb-2 text-center">
              {getLocalized("noDishesToSuggest")} {note}
            </h4>
          ) : null}
          <Survey onAnswer={onDishAnswer} key={"suggestions"} activeQuestion={activeSuggestionIndex}>
            {suggestions.map((menuItem) => {
              const fileName = menuItem.images && menuItem.images[0] && menuItem.images[0].fileName;
              return (
                <SurveySimpleQuestion id={menuItem.id} key={menuItem.id}>
                  <div className="dish-question" onClick={navigateToItem}>
                    <div
                      className="question-image-wrapper"
                      style={{
                        backgroundImage: `url(${menuItemStub})`,
                      }}
                    >
                      <Img
                        className="image-cover"
                        src={`${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${fileName}&width=500&height=500`}
                      />
                    </div>
                    <h4 className="question-title d-flex flex-column justify-content-center">{menuItem.name}</h4>
                  </div>
                </SurveySimpleQuestion>
              );
            })}
          </Survey>
          <p className="survey-footer text-gray-80">{getLocalized('dishForYou')}</p>
        </>
      ) : (
        <MealSurveyFinished suggestions={suggestions} />
      )}
    </div>
  );
};

export default inject("store")(observer(MealSurvey));
