import React from 'react';
import {inject, observer} from "mobx-react";
import ellipse4 from '../../assets/img/ellipse 4.png'
import ellipse5 from '../../assets/img/ellipse 5.png'
import notFound from '../../assets/img/icon-not-found.png'
import './NotFound.css'
import logo from "../../assets/img/logo.svg";


const NotFound = ({store: {getLocalized}}) => {
    return (
        <div className="not-found-wrapper">
            <div>
                <p className='not-found-label'>{getLocalized('oops')}</p>
                <p className='not-found-sublabel'>{getLocalized('looksLikeThisPageDoesntExist')}</p>
            </div>
            <div className="not-found-image-wrap">
                <img className='not-found-image' src={ellipse4} alt=""/>
                <img className='not-found-image' src={ellipse5} alt=""/>
                <img className='not-found-image' src={notFound} alt=""/>
            </div>
            <footer className='not-found-footer'>
                <img src={logo} alt=""/>
            </footer>
        </div>
    )
};

export default inject('store')(observer(NotFound));
