import React, {useState, useEffect} from 'react';
import {inject, observer} from "mobx-react";
import './SurpriseMeButton.css';
import { withRouter } from 'react-router-dom';

const getRandom = (max) => {
	return Math.floor(Math.random() * max);
}

const SurpriseMeButton = ({store: {getLocalized, menuCategories}, history, children, type = 'secondary', itemId}) => {

	const [surpriseMI, setSurpriseMI] = useState(null);
	useEffect(() => {
		const menuItems = menuCategories.reduce((acc, mc) => [...acc, ...mc.menuItems], []).filter(i => i.id !== itemId);
		setSurpriseMI(menuItems[getRandom(menuItems.length)])
	}, [itemId, menuCategories])
	if (!surpriseMI) return null;

	const onNavigate = () => {
		const url = `/dish?categoryId=${surpriseMI.menuCategory.id}&itemId=${surpriseMI.id}&surprize=true`;
		if (itemId) {
			history.replace(url);
		} else {
			history.push(url);
		}
	}
	return <div className='mb-3' onClick={onNavigate}>
		<div className={`btn btn-${type}`}>
			{children ? children : <>
				<span role="img" aria-label="party">🎉</span>	
				{getLocalized('surpriseMe')}!	
				<span role="img" aria-label="party">🎉</span>	
			</>}
		</div>
	</div>
	;
	
};

export default inject('store')(observer(withRouter(SurpriseMeButton)));
