import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const useStyles1 = makeStyles(theme => ({
    error: {
        display: 'flex',
        backgroundColor: '#ff5959',
    },
    icon: {
        fontSize: 20,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
}));


function MySnackbarContent(props) {
    const classes = useStyles1();
    const { className, message, onClose, ...other } = props;

    return (
        <SnackbarContent
            className={classes.error}
            aria-describedby="client-snackbar"
            message={
                <div id="client-snackbar" className={classes.message}>
                    {message}
                </div>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}




const ErrorSnackbar = ({ handleSnackbarClose, open, message }) => {
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <MySnackbarContent
                    message={message}
                    onClose={handleSnackbarClose}
                />
            </Snackbar>
        </div>
    );
}



export default ErrorSnackbar;