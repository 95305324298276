import React from "react";
import { inject, observer } from "mobx-react";
import "./SpotCheckers.css";
import RestaurantHeader from "../../components/RestaurantHeader/RestaurantHeader";

const SpotCheckers = ({
  store: { spotCheckers, getLocalized },
  history
}) => {

  const onClick = (itemId) => {
      history.push(`/spot-checker/?id=${itemId}`);
  };

  const list = spotCheckers.map((item) => {
    const fileName = item.mediaFile && item.mediaFile.fileName;
    return (
      <div key={item.id} className="col-12 mb-3">
        <div onClick={() => onClick(item.id)} className="checker-item blur" style={{backgroundImage: fileName ? `url(${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${fileName}&width=500&height=500)` : null}}>
          <p className="checker-item-name p-2">{item.name}</p>
        </div>
      </div>
    );
  });
  return (
    <div className="page p-4">
      <RestaurantHeader />
      <h3 className="mb-2">{getLocalized("checklists")}</h3>
      <div className="row">{list}</div>
    </div>
  );
};

export default inject("store")(observer(SpotCheckers));
