import React, { useEffect, useState } from 'react';

const Img = ({src, alt,...props}) => {
	const [hasError, setHasError] = useState(false);

	const onError = () => {
		setHasError(true);
	};

	useEffect(() => {
		setHasError(false);
	}, [src]);

	return hasError ? null : <img src={src} alt={alt} {...props} onError={onError} />
}

export default Img;