import React, { useEffect } from 'react';
import {inject, observer} from "mobx-react";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from "./pages/Home/Home";
import Nps from "./pages/Nps/Nps";
import Menu from "./pages/Menu/Menu";
import MenuItems from "./pages/MenuItems/MenuItems";
import Dish from "./pages/Dish/Dish";
import SpotChecker from "./pages/SpotChecker/SpotChecker";
import SpotCheckers from "./pages/SpotCheckers/SpotCheckers";
import NotFound from "./pages/NotFound/NotFound";
import MealSurvey from "./pages/MealSurvey/MealSurvey";
import { Helmet } from 'react-helmet';
import {QrCodePageTheme} from "./store/store";
import Wine from './pages/Wine/Wine';

const App = ({store: {getLocalized, restaurant: {qrCodePageTheme, id}}}) => {
    useEffect(() => {
        document.body.className = qrCodePageTheme === QrCodePageTheme.Dark ? 'theme-dark' : 'theme-light';
    }, [qrCodePageTheme])
    return id &&
    <>
        <Helmet>
            <title>
                    {getLocalized('leaveYourFeedbackAboutOurRestaurant')}
            </title>
        </Helmet>
        <Router basename={`/`}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/nps" component={Nps} />
                <Route path="/meal-survey" component={MealSurvey} />
                <Route path="/menu" component={Menu} />
                <Route path="/menu-items" component={MenuItems} />
                <Route path="/spot-checker" component={SpotChecker} />
                <Route path="/spot-checkers" component={SpotCheckers} />
                <Route path="/dish" component={Dish} />
                <Route path="/alcohol" component={Wine} />                
                <Route path="/404" component={NotFound}/>
                <Redirect to="/404" />
            </Switch>
        </Router>
    </>;
};

export default inject('store')(observer(App));
