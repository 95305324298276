import bad from "./img/bad.svg";
import neutral from "./img/neutral.svg";
import good from "./img/good.svg";
import very_good from "./img/very_good.svg";
import excellent from "./img/excellent.svg";
import newIcon from "./img/icon-new-dish.svg";
import discount from "./img/icon-discount.svg";
import vegan from "./img/icon-vegan.svg";
import spicy from "./img/icon-spicy.svg";
import garlic from "./img/icon-garlic.svg";
import bestseller from "./img/icon-bestseller.svg";
import alcoholLevel from "./img/alcohol-level.svg";
import productionYear from "./img/production-year.svg";
import sugarType from "./img/sugar-type.svg";
import colorType from "./img/color-type.svg";
import carbonatedType from "./img/carbonated-type.svg";
import beerStyle from "./img/icon-beer-style.svg";
import ibu from "./img/icon-IBU.svg";
import gravity from "./img/icon-gravity.svg";
import cocktailType from "./img/icon-cocktail-type.svg";
import cocktailTimeType from "./img/icon-cocktail-time-type.svg";
import region from "./img/icon-region.svg";

export const labels = [
    {
        id: 1,
        label: 'howWouldYouRateQualityOfFoodInOurRestaurant'
    },
    {
        id: 2,
        label: 'howWouldYouRateQualityOfServiceInOurRestaurant'
    },
    {
        id: 3,
        label: 'howWouldYouRateAtmosphereInOurRestaurant'
    },
];

export const smiles = [
    {
        smile: bad,
        label: 'badly'
    },
    {
        smile: neutral,
        label: 'neutral'
    },
    {
        smile: good,
        label: 'good'
    },
    {
        smile: very_good,
        label: 'veryGood'
    },
    {
        smile: excellent,
        label: 'perfectly'
    }
];

export const pages = {
    0: '/',
    1: '/service',
    2: '/atmosphere',
    3: '/advice',
    4: '/upload_photo'
};

export const icons = [
    {
        icon: newIcon,
        label: 'new',
    },
    {
        icon: discount,
        label: 'sale',
    },
    {
        icon: vegan,
        label: 'vegan',
    },
    {
        icon: spicy,
        label: 'spicy',
    },
    {
        icon: garlic,
        label: 'garlic',
    },
    {
        icon: bestseller,
        label: 'bestseller',
    }
];

export const wineAttributes = [
    {
        icon: alcoholLevel,
        label: 'alcoholLevel',
    },
    {
        icon: productionYear,
        label: 'productionYear',
    },
    {
        icon: sugarType,
        label: 'sugarType',
        values: ['dry', 'offDry', 'semiSweet', 'sweet']
    },
    {
        icon: colorType,
        label: 'colorType',
        values: ['red', 'white', 'rose', 'orange']
    },
    {
        icon: carbonatedType,
        label: 'carbonatedType',
        values: ['quiet', 'sparkling']
    },
    {
        icon: region,
        label: 'country',
    },    
    {
        icon: region,
        label: 'region',
    },    
];
export const beerAttributes = [    
    {
        icon: beerStyle,
        label: 'beerStyle',
        values: ['ale', 'lager', 'mixed']
    },
    {
        icon: alcoholLevel,
        label: 'alcoholLevel',
        name: 'abv',
    },
    {
        icon: ibu,
        label: 'ibu',
    },
    {
        icon: gravity,
        label: 'gravity',
    },
];
export const cocktailAttributes = [
    {
        icon: cocktailType,
        label: 'cocktailType',
        values: ['hotCocktail', 'long', 'short', 'shot']
    },
    {
        icon: cocktailTimeType,
        label: 'cocktailTimeType',
        values: ['aperitif', 'digestif', 'anyTime']
    },
];

// enum SugarType {
//     'dry' = 1,
//     'offDry',
//     'semiSweet',
//     'sweet',
//   }
  
//   enum ColorType {
//     'red' = 1,
//     'white',
//     'rose',
//     'orange',
//   }
  
//   enum CarbonatedType {
//     'quiet' = 1,
//     'sparkling',
//   }


export const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';

export const constraints = {video: {facingMode: "environment"}, audio: false};

export const blancActive = Array.from({length: 5}, v => false);

export const numbers = Array.from({length: 11}, (v, i) => ({
    number: i,
    color: i <= 4 ? '#F06452' : (i <= 7 ? '#FFCC11' : '#2ECC71'),
}));

export const sendingError = "somethingWentWrong";

export const cameraError = "Camera error. Please try again.";
