import React from "react";
import { inject, observer } from "mobx-react";
import { icons } from "../../assets/constants";
import "./MenuItemAttributes.css";

const MenuItemAttributes = ({ store: { getLocalized }, attributes }) => {
  return attributes && (
  	<div className="d-flex flex-wrap mr-5">
		{attributes.map((item) => (
			<div key={item.id} className="attribute d-flex flex-column align-items-center">
				<img src={icons[item.id - 1].icon} alt="" />
				<p className="attribute-label text-gray-80">{getLocalized(icons[item.id - 1].label)}</p>
			</div>
    	))}
	</div>
  );
};

export default inject("store")(observer(MenuItemAttributes));
