export const getPluralForm = (number, variants = '') => {
  if (Array.isArray(variants)) {
    if (isNaN(number)) number = 5
    const cases = [2, 0, 1, 1, 1, 2]
    return variants[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[Math.min(number % 10, 5)]
    ]
  }
  return variants
}
