import React from 'react'
import { inject, observer } from 'mobx-react'
import MenuItemTile from '../MenuItemTile/MenuItemTile'
import { menuUnitsType } from '../MenuItemSizes/MenuItemSizes'
import { getPluralForm } from '../../helpers/pluralForm'

const MenuItemIngredients = ({
  store: { getLocalized },
  ingredients,
  isCocktail,
}) => {
  const list = Array.isArray(ingredients)
    ? ingredients.map((item, index) => {
        const {
          name,
          image,
          id,
          percentage,
          measurementUnitsType,
          amountStr,
        } = item
        return (
          <MenuItemTile
            key={id}
            fileName={image}
            name={name}
            amount={!isCocktail ? percentage : amountStr}
            measurementUnitsType={
              !isCocktail
                ? '%'
                : ` ${getLocalized(
                    getPluralForm(
                      amountStr,
                      menuUnitsType[measurementUnitsType],
                    ),
                  )}`
            }
            className={`mb-2 ${(index + 1) % 3 === 0 ? '' : 'mr-4'}`}
          />
        )
      })
    : []

  return list.length ? (
    <>
      <div className="label-field background-secondary p-3 mx-n3 mb-3">
        {getLocalized('ingredients')}
      </div>
      <div className="d-flex flex-wrap mb-4">{list}</div>
    </>
  ) : null
}

export default inject('store')(observer(MenuItemIngredients))
