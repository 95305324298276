import React from "react";
import { inject, observer } from "mobx-react";
import "./Menu.css";
import RestaurantHeader from "../../components/RestaurantHeader/RestaurantHeader";
import MenuItem from "../../components/MenuItem/MenuItem";
import { Link } from "react-router-dom";

const Menu = ({ store: { menuCategories, wineCategories, getLocalized }, history }) => {

  const list = [
    ...menuCategories.filter((item) => !!item.menuItems.length).map((item) => {
      return (
        <div key={`${item.id}-menu`} className="col-6 p-2">
          <Link to={`/menu-items?menuCategoryId=${item.id}`}>
            <MenuItem name={item.name} image={item.image} type="category"/>
          </Link>
        </div>
      );
    }),    
    ...wineCategories.filter((item) => !!item.alcoholicItems.length).map((item) => {
      return (
        <div key={`${item.id}-alcohol`} className="col-6 p-2">
          <Link to={`/menu-items?wineCategoryId=${item.id}`}>
            <MenuItem name={item.name} image={item.image} type="category"/>
          </Link>
        </div> 
      );
    })
  ];
  return (
    <div className="page p-4">
      <RestaurantHeader />
      <h3 className="mb-2">{getLocalized("menu")}</h3>

      <div className="row mx-n2">{list}</div>
    </div>
  );
};

export default inject("store")(observer(Menu));
