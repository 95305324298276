import React, { useEffect, useRef } from 'react';
import {inject, observer} from "mobx-react";
import {cameraError, constraints} from "../../assets/constants";
import './Camera.css';

const Camera = ({store: {getLocalized}, setImgSrc, handleSnackbarOpen}) => {

    const cameraViewRef = useRef(null);
    const cameraSensorRef = useRef(null);
    const cameraOutputRef = useRef(null);

    const cameraStart = () => {
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => cameraViewRef.current.srcObject = stream)
            .catch(error => handleSnackbarOpen(cameraError));
    };

    const takePicture = () => {
        cameraSensorRef.current.width = cameraViewRef.current.videoWidth;
        cameraSensorRef.current.height = cameraViewRef.current.videoHeight;

        cameraSensorRef.current.getContext("2d").drawImage(cameraViewRef.current, 0, 0);
        cameraSensorRef.current.toBlob(blob => setImgSrc(blob, "image/jpeg"));
        stopCamera(cameraViewRef.current.srcObject);
    };

    function stopCamera(stream) {
        stream.getTracks().forEach(track => {
            track.stop();
            stream.removeTrack(track);
        });
    }


    useEffect(cameraStart, []);

    return (
        <main className="camera">
            <canvas ref={cameraSensorRef}
                    className='camera-sensor'
            >
            </canvas>
            <video ref={cameraViewRef}
                   autoPlay
                   playsInline
                   className='camera-view'
            >
            </video>
            <img ref={cameraOutputRef}
                 src="//:0"
                 alt=""
                 className='camera-output'
            />
            <button onClick={takePicture} className='btn btn-primary btn-sm btn-rounded camera-trigger'>{getLocalized('photo')}</button>
        </main>
    )
};

export default inject('store')(observer(Camera));
