import React from "react";
import { inject, observer } from "mobx-react";
import MenuItemTile from "../MenuItemTile/MenuItemTile";

const MenuItemAllergens = ({ store: { getLocalized }, ingredients }) => {
  const allergens = ingredients && ingredients.filter((item) => item.isAllergen);
  const allergensList =
    allergens &&
    allergens.map((item, index) => {
      const { name, image, id } = item;
      return <MenuItemTile key={id} fileName={image} name={name} className={`mb-2 ${(index + 1) % 3 === 0  ? '': 'mr-4'}`} />;
    });

  return allergens && !!allergens.length ? (
    <>
      <div className="label-field background-secondary p-3 mx-n3 mb-3">{getLocalized("allergens")}</div>
      <div className="d-flex flex-wrap mb-4">{allergensList}</div>
    </>
  ) : null;
};

export default inject("store")(observer(MenuItemAllergens));
