import React, {useState, useEffect, cloneElement} from 'react';
import {inject, observer} from "mobx-react";
import SwipeableCards from "../SwipeableCards/SwipeableCards";
import iconX from "../../assets/img/icon-x.svg";
import iconCheck from "../../assets/img/icon-check.svg";

import Card from "../SwipeableCards/Card";
import './Survey.css'

const Survey = ({store: {getLocalized}, activeQuestion, mode = '', children, onAnswer}) => {
	const [activeQuestionAnswer, setActiveQuestionAnswer] = useState(null);
	const [effect, setEffect] = useState(null);

	useEffect(() => {
		let timer;
		if (effect !== null) {
		  timer = setTimeout(() => {
			setEffect(null);
		  }, 400);
		}
		return () => {
		  if(timer) {
			clearTimeout(timer)
		  }
		}
	}, [effect]);

	const onConfirm = () => {
		setEffect(true);
		setActiveQuestionAnswer(null);
		onAnswer(activeQuestion, activeQuestionAnswer !== null ? activeQuestionAnswer : true);
	}
	const onDismiss = () => {
		setEffect(false);
		setActiveQuestionAnswer(null);
		onAnswer(activeQuestion, activeQuestionAnswer !== null ? activeQuestionAnswer : false);
	}
	const active = children[activeQuestion];
	return (
		<div className="survey">
			{active.type === SurveySimpleQuestion
				? 
				<>
					<SwipeableCards className="swipeable-survey" activeIndex={activeQuestion}>
						{children.map((q, index) => (	
							<Card key={q.key} onSwipeLeft={onDismiss} onSwipeRight={onConfirm}>
								<SurveySimpleQuestion {...q.props} />
							</Card>
						))}
					</SwipeableCards>
				</>
				: <SurveyMultiSelectQuestion {...active.props} onChange={setActiveQuestionAnswer} />
			}
			{(() => {
				switch(active.type) {
					case SurveyMultiSelectQuestion:
						return (
							<div className="d-flex flex-column justify-items-stretch px-3 py-2">
								<button
									className='btn btn-primary btn-rounded btn-lg'
									onClick={onConfirm}
								>
									{getLocalized('next')}
								</button>
							</div>
						);
					case SurveySimpleQuestion: 
						return (
							<div className="btn-group mt-4 mb-2 p-2">
								<button
									className={`btn btn-danger btn-survey ${effect === false ? 'shake': ''}`}
									onClick={onDismiss}
								>
									<img src={iconX}  alt=""/>
								</button>
								<button
									className={`btn btn-primary btn-survey ${effect === true ? 'shake': ''}`}
									onClick={onConfirm}
								>
									<img src={iconCheck}  alt=""/>
								</button>
							</div>
						);
					default: 
						return null
					
				}
			})()}
		</div>
	)
};

export const SurveySimpleQuestion = ({children}) => {
	return <>{children}</>
}

export const SurveyMultiSelectQuestion = ({children, options, onChange}) => {
	const [multiOptions, setMuliOptions] = useState([]);

	useEffect(() => {
		onChange(multiOptions);
	}, []);

	const toggleOption = (key) => {
		const value = multiOptions.includes(key) ? multiOptions.filter(i => i !== key) : [...multiOptions, key];
		setMuliOptions(value);
		onChange(value);
	}
	return (
		<div className="survey-multiselect-question d-flex flex-column">
			{children}
			<div className="d-flex flex-column">
				{options.map(opt => 
					cloneElement(opt, {
						...opt.props, 
						onClick:() => toggleOption(opt.key), 
						className: `${opt.props.className} ${multiOptions.includes(opt.key) ? 'selected': ''}`
					}
				))}
			</div>
		</div>
	);
}

export default inject('store')(observer(Survey));
