import React from 'react'
import {inject, observer} from "mobx-react";


import './Circle.css'

const desktop = window.innerWidth > 400;

const Circle = ({ item: { number, color }, active, onSelect, index, store: {getLocalized} }) => {
    const currentClass = active ? 'circle circle-active' : 'circle';
    const backgroundColor = active ? {background: color} : {background: 'transparent'}
    const numberColor = active ? {color: '#fff'} : {color};
    return (
        <div className='circle-wrapper'>
            <div className={currentClass} style={backgroundColor} onClick={() => onSelect(index)}>
                <div className='number' style={numberColor}>{number}</div>
            </div>
            {desktop &&
            <p className='advise-label'>
                {number === 0 ?
                    getLocalized('never')
                    : (number === 10 ?
                        getLocalized('mostLikely')
                        : '')}
            </p>
            }
        </div>
    )
};

export default inject('store')(observer(Circle));
