import React from "react";
import stub from "../../assets/img/menu-item-stub.svg";
import "./MenuItemTile.css";

const MenuItemTile = ({ fileName, name, amount, measurementUnitsType, className = '', onClick }) => {
  const src = fileName ? `${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${fileName}&width=500&height=500` : stub;
  return (
    <div className={`item-image-wrap d-flex flex-column ${className}`} onClick={onClick}>
      <img className="item-image mb-2" src={src} alt="" />
      <p className="text-ellipsis">{name}</p>
  	  {!!amount && <span className="amount-info">{`${amount}${measurementUnitsType}`}</span>}
    </div>
  );
};

export default MenuItemTile;
