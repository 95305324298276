import React, { useState} from 'react';
import {inject, observer} from "mobx-react";
import Header from "../Header/Header";
import photos from '../../assets/img/photos.svg'
import {acceptedFileTypes} from "../../assets/constants";
import './UploadPhoto.css'


const UploadPhoto = ({store: {getLocalized}, nextPage, imgSrc, setImgSrc}) => {

    const [src, setSrc] = useState(null);

    const handleFile = (e) => {
        const file = e.target.files[0];
        const img = new Image();
        img.src = window.URL.createObjectURL( file );
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(file)
            setSrc(reader.result);
        }, false);
        if (file) reader.readAsDataURL(file);
    };

    let conditionalInput = !imgSrc ?
        <div className='upload-circle'>
            <label>
                <input type="file"
                       name="fileUpload"
                       className='photo-input'
                       accept={acceptedFileTypes}
                       onChange={handleFile}
                />
                <img src={photos} alt=""/>
            </label>
        </div> :
        <img src={src} alt="" className='receipt-photo'/>


    return (
        <>
            <Header>
                {getLocalized('takePhotoOf')}
                <br/> {getLocalized('yourCheck')}
            </Header>
            <div className="answer-container upload-container background-primary">
                {conditionalInput}
                <button
                    className='btn btn-primary btn-rounded photo-button'
                    disabled={!imgSrc}
                    onClick={nextPage}
                >
                    {getLocalized('next')}
                </button>
                {/*<button*/}
                {/*    className='reject'*/}
                {/*    disabled={!imgSrc}*/}
                {/*    onClick={nextPage}*/}
                {/*>Пропустити*/}
                {/*</button>*/}
            </div>
        </>
    )
};

export default inject('store')(observer(UploadPhoto));
