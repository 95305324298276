import React from 'react';
import {inject, observer} from "mobx-react";
import {MenuItem ,Select} from '@material-ui/core';
import { createStyles, makeStyles} from '@material-ui/core/styles';

const LanguageSelect = ({store: {language, setLanguage}}) => {
    const classes = useStyles();
    return(
        <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            disableUnderline
            MenuProps={{...menuProps, classes: {paper: classes.dropdownStyle}}}
            inputProps={{
                classes: {
                    root: classes.textField,
                },
            }}
        >
            <MenuItem value='en'>EN</MenuItem>
            <MenuItem value='ru'>RU</MenuItem>
            <MenuItem value='uk'>UA</MenuItem>
        </Select>
    )
};

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: 32,
            borderRadius: 4,
            padding: 8,
        },
        dropdownStyle: {
            width: 40,
            cursor: 'pointer',
        },
        menuItem: {
            height: 30,
        },
    }),
);

const menuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

export default inject('store')(observer(LanguageSelect));
