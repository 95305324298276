import React from 'react';
import {inject, observer} from "mobx-react";
import './Image.css'

const Image = ({ store: {getLocalized}, item: { smile, label }, active, onSelect, index }) => {
    const currentClass = active ? 'wrapper active' : 'wrapper';
    return (
        <div className='smile-wrapper' onClick={() => onSelect(index)}>
            <div className={currentClass}>
                <img src={smile} alt={label} className='smile'/>
            </div>
            <div className='label text-gray-50'>{getLocalized(label)}</div>
        </div>
    )
};

export default inject('store')(observer(Image));
