import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import clockWhite from "../../assets/img/icon-clock-white.svg";
import clockBlack from "../../assets/img/icon-clock-black.svg";
import "./Dish.css";
import { QrCodePageTheme } from "../../store/store";
import SurpriseMeButton from "../../components/SurpriseMeButton/SurpriseMeButton";
import MenuItemPairs from "../../components/MenuItemPairs/MenuItemPairs";
import MenuItemAllergens from "../../components/MenuItemAllergens/MenuItemAllergens";
import MenuItemDescription from "../../components/MenuItemDescription/MenuItemDescription";
import MenuItemSizes from "../../components/MenuItemSizes/MenuItemSizes";
import MenuItemAttributes from "../../components/MenuItemAttributes/MenuItemAttributes";
import MenuItemHeader from "../../components/MenuItemHeader/MenuItemHeader";

const Dish = ({
  store: {
    menuCategories,
    wineCategories,
    getLocalized,
    restaurant: { currency, qrCodePageTheme },
  },
  history,
}) => {
  const params = new URL(window.location.href).searchParams;
  const categoryId = +params.get("categoryId");
  const itemId = +params.get("itemId");

  const isSurvey = !!params.get("activeIndex");
  const activeSurveyIndex = +params.get("activeIndex");
  const surprize = !!params.get("surprize");

  const ref = useRef(null);
  const clock = qrCodePageTheme === QrCodePageTheme.Dark ? clockWhite : clockBlack;

  const item = (menuCategories.find(c => c.id === categoryId) || {menuItems: []}).menuItems.find(mi => mi.id === itemId) || {};

  const { images, name, menuCategory, cookingTime, sizes, menuItemAttributes, description, menuItemsPairs, menuItemIngredients } = item;

  const fileName = images && images[0] && images[0].fileName;

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [itemId, categoryId]);

  const onOtherRecommendations = () => {
    if(activeSurveyIndex > 0) {
      history.goBack();
      setTimeout(() => {
        const params = new URL(window.location.href).searchParams;
        history.replace(`${window.location.pathname}?answers=${params.get('answers')}&activeIndex=${activeSurveyIndex}`);

      }, 100);
    } else {
      history.push('/menu')
    }
  }

  const handlePair = (categoryId, id, type) => {
    const items = type === 2 ? 
      (menuCategories.find(c => c.id === categoryId) || {menuItems: []}).menuItems:
      (wineCategories.find(c => c.id === categoryId) || {wineItems: []}).wineItems;
    if (items.find(i => i.id === id)) {
      history.push(`/${type === 2 ? 'dish' : 'wine'}?categoryId=${categoryId}&itemId=${id}`)
    }
  }

  return (
    <div className="page" ref={ref}>
      <MenuItemHeader name={name} fileName={fileName} category={menuCategory} />
      <div className="p-3">
        <div className="d-flex align-items-center mb-3">
          <img className="mr-2" src={clock} alt="" />
          <span className="cooking-time text-gray-90">{cookingTime} min</span>
        </div>
        <MenuItemSizes sizes={sizes} currency={currency}/>
        <MenuItemAttributes attributes={menuItemAttributes}/>
        <MenuItemDescription description={description} />
        <MenuItemAllergens ingredients={menuItemIngredients} />
        <MenuItemPairs items={menuItemsPairs} onClick={handlePair}/>

        {surprize && <div className="surprize-container">
          <SurpriseMeButton type="primary" itemId={itemId}>{getLocalized('tryAgain')}</SurpriseMeButton>
        </div>}

        {isSurvey && <div className="survey-container">
          <div onClick={onOtherRecommendations} className="btn btn-primary">{getLocalized("otherRecommendations")}</div>
        </div>}
      </div>
    </div>
  );
};

export default inject("store")(observer(Dish));
