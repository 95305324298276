export const locales = {
  pleaseLeaveYourFeedback: {
    ru: 'Оставьте, пожалуйста, ваш отзыв',
    uk: 'Залиште, будь ласка, ваш відгук',
    en: 'Please leave your feedback',
  },
  aboutOurRestaurant: {
    ru: 'о нашем ресторане',
    uk: 'про наш ресторан',
    en: 'about our restaurant',
  },
  aboutOurRestaurantAndTake: {
    ru: 'о нашем ресторане и возьмите',
    uk: 'про наш ресторан та візьміть',
    en: 'about our restaurant and take',
  },
  partInLottery: {
    ru: 'участие в розыгрыше',
    uk: 'участь у розіграші',
    en: 'part in the lottery',
  },
  $25: {
    ru: '500 грн',
    uk: '500 грн',
    en: '$25',
  },
  howWouldYouRateQualityOfFoodInOurRestaurant: {
    ru: 'Оцените качество пищи в нашем ресторане',
    uk: 'Оцініть якість їжі в нашому ресторані',
    en: 'How would you rate the quality of the food in our restaurant?',
  },
  howWouldYouRateQualityOfServiceInOurRestaurant: {
    ru: 'Оцените обслуживание в нашем ресторане',
    uk: 'Оцініть обслуговування в нашому ресторані',
    en: 'How would you rate the quality of the service in our restaurant?',
  },
  howWouldYouRateAtmosphereInOurRestaurant: {
    ru: 'Оцените атмосферу в нашем ресторане',
    uk: 'Оцініть атмосферу в нашому ресторані',
    en: 'How would you rate the atmosphere in our restaurant?',
  },
  badly: {
    ru: 'Плохо',
    uk: 'Погано',
    en: 'Badly',
  },
  neutral: {
    ru: 'Нейтрально',
    uk: 'Нейтрально',
    en: 'Neutral',
  },
  good: {
    ru: 'Хорошо',
    uk: 'Добре',
    en: 'Good',
  },
  veryGood: {
    ru: 'Очень хорошо',
    uk: 'Дуже добре',
    en: 'Very good',
  },
  perfectly: {
    ru: 'Прекрасно',
    uk: 'Чудово',
    en: 'Perfectly',
  },
  next: {
    ru: 'Далее',
    uk: 'Далi',
    en: 'Next',
  },
  'howWouldYouRateAtmosphereInOurRestaurant?': {
    ru: 'Оцените атмосферу в нашем ресторане',
    uk: 'Оцініть атмосферу в нашому ресторані',
    en: 'How would you rate the atmosphere in our restaurant?',
  },
  howLikelyIsItThatYouRecommendOurRestaurant: {
    ru: 'Насколько вероятно, что вы посоветуете наш ресторан',
    uk: 'Наскільки ймовірно, що ви порадите наш ресторан',
    en: 'How likely is it that you recommend our restaurant',
  },
  never: {
    ru: 'Никогда',
    uk: 'Ніколи',
    en: 'Never',
  },
  mostLikely: {
    ru: 'Наиболее вероятно',
    uk: 'Найбільш ймовірно',
    en: 'Most likely',
  },
  toFriendOrColleague: {
    ru: ' друзьям и родственникам?',
    uk: ' друзям та родичам?',
    en: ' to a friend or colleague?',
  },
  takePhotoOf: {
    ru: 'Сделайте фото',
    uk: 'Зробіть фото',
    en: 'Take a photo of',
  },
  yourCheck: {
    ru: 'вашего чека',
    uk: 'вашого чеку',
    en: 'your check',
  },
  makeAnotherPhoto: {
    ru: 'Переснять',
    uk: 'Перезняти',
    en: 'Take another photo',
  },
  pleaseLeaveYour: {
    ru: 'Оставьте свои',
    uk: 'Залиште свої',
    en: 'Please leave your',
  },
  contactDetails: {
    ru: 'контактные данные',
    uk: 'контактні дані',
    en: 'contact details',
  },
  name: {
    ru: 'Имя',
    uk: "Ім'я",
    en: 'Name',
  },
  phoneNumber: {
    ru: 'Телефонный номер',
    uk: 'Номер телефону',
    en: 'Phone number',
  },
  iConsentMyPersonalDataUsage: {
    ru: 'Разрешаю использовать мои персональные данные',
    uk: 'Дозволяю використовувати мої персональні дані',
    en: 'I consent my personal data usage',
  },
  takePart: {
    ru: 'Отправить',
    uk: 'Надіслати ',
    en: 'Take part!',
  },
  thanksForYourFeedback: {
    ru: 'Спасибо за отзыв!',
    uk: 'Дякуємо за відгук!',
    en: 'Thanks for your feedback!',
  },
  weWillContactYouSoon: {
    ru: 'Мы обязательно с вами свяжемся в ближайшее время',
    uk: "Ми обов'язково з вами зв'яжемося найближчим часом",
    en: 'We will contact you soon.',
  },
  mrTomatoTeam: {
    ru: 'Команда Mr. Tomato',
    uk: 'Команда Mr. Tomato',
    en: 'Mr. Tomato team',
  },
  somethingWentWrong: {
    ru: 'Данные ни были отправлены. Попробуйте еще.',
    uk: 'Данi не були надiсланi. Спробуйте ще.',
    en: 'Oops, something went wrong!',
  },
  'cameraErrorPleaseTryAgain.': {
    ru: 'Ошибка работы камеры. Попробуйте еще.',
    uk: 'Помилка роботи камери. Спробуйте ще.',
    en: 'Camera error. Please try again.',
  },
  leaveFeedback: {
    ru: 'Оставить отзыв',
    uk: 'Залишити відгук',
    en: 'Leave feedback',
  },  
  checkOurSanitization: {
    ru: 'Наши правила чистоты',
    uk: 'Наші правила чистоти',
    en: 'Check our sanitization',
  },
  viewMenu: {
    ru: 'Меню',
    uk: 'Меню',
    en: 'View menu',
  },
  suggestMeal: {
    ru: 'Предложить блюдо?',
    uk: 'Запропонувати страву?',
    en: 'Suggest meal',
  },
  surpriseMe: {
    ru: 'Удивите меня',
    uk: 'Здивуйте мене',
    en: 'Surprise me',
  },
  areYouInAHurry: {
    ru: 'Вы спешите?',
    uk: 'Ви поспішаєте?',
    en: 'Are you in a hurry?',
  },
  doYouWantLightDish: {
    ru: 'Вы хотите съесть что-нибудь легкое?',
    uk: "Ви хочете з'їсти щось легке?",
    en: 'Do you want to eat something light?',
  },
  doYouHaveAllergie: {
    ru: 'У вас есть пищевая аллергия?',
    uk: 'Чи є у вас харчова алергія?',
    en: 'Do you have any food allergies?',
  },
  pleaseSelectAllergens: {
    ru: 'Пожалуйста, выберите продукты на которые у вас аллергия',
    uk: 'Будь-ласка, виберіть продукти на які у вас алергія',
    en: 'Please select ingredients to which you have an allergy',
  },
  doYouWantSpicyDish: {
    ru: 'Вы хотите острое блюдо?',
    uk: 'Чи бажаете ви гостру страву?',
    en: 'Do you want a spicy dish?',
  },
  doYouWantVegetarianDish: {
    ru: 'Вы хотите вегетарианское блюдо?',
    uk: 'Чи бажаете ви вегетаріанську страву?',
    en: 'Do you want a vegetarian dish?',
  },
  noDishesToSuggest: {
    ru: 'У нас нет блюд, которые мы можем вам предложить.',
    uk: 'У нас немає страв, які ми можемо вам запропонувати. ',
    en: 'There are no dishes to suggest to you.',
  },
  noDishesToSuggestMore: {
    ru: 'У нас больше нет блюд, которые мы можем вам предложить.',
    uk: 'У нас більше немає страв, які ми можемо вам запропонувати. ',
    en: 'There are no dishes to suggest to you more.',
  },
  notInAHurry: {
    ru: 'Если вы не спешите, можете выбрать одно из следующих блюд:',
    uk: 'Якщо ви не поспішаєте, можете обрати одну з наступних страв:',
    en:
      'But if you are not in a hurry, you can select something from the list below:',
  },
  notLightDish: {
    ru: 'Но если вы хотите съесть что-то сытное, можете выбрать ниже',
    uk: "Але якщо ви хочете з'їсти щось ситне, можете обрати нижче",
    en: "But if you don't mind to eat some heavy dish, you can select below",
  },
  notSpicyDish: {
    ru: 'Но если вы хотите съесть что-то не острое, можете выбрать ниже',
    uk: "Але якщо ви хочете з'їсти не гостре, можете обрати нижче",
    en: "But if you don't mind to eat some notSpicy dish, you can select below",
  },
  new: {
    ru: 'Новое',
    uk: 'Нове',
    en: 'New',
  },
  sale: {
    ru: 'Скидка',
    uk: 'Знижка',
    en: 'Sale',
  },
  vegan: {
    ru: 'Вегетарианское',
    uk: 'Вегетаріанське',
    en: 'Vegetarian',
  },
  spicy: {
    ru: 'Острое',
    uk: 'Гостре',
    en: 'Spicy',
  },
  garlic: {
    ru: 'С чесноком',
    uk: 'З часником',
    en: 'Garlic',
  },
  bestseller: {
    ru: 'Бестселлер',
    uk: 'Бестселер',
    en: 'Bestseller',
  },
  descriptionCapital: {
    ru: 'Описание',
    uk: 'Опис',
    en: 'Description',
  },
  allergens: {
    ru: 'Аллергены',
    uk: 'Алергени',
    en: 'Allergens',
  },
  ingredients: {
    ru: 'Ингредиенты',
    uk: 'Інгедієнти',
    en: 'Ingredients',
  },
  goesGreatWith: {
    ru: 'Хорошо сочетается с',
    uk: 'Добре поєднується з',
    en: 'Goes great with',
  },
  checklists: {
    ru: 'Чек-листы',
    uk: 'Чек-листи',
    en: 'Checklists',
  },
  menu: {
    ru: 'Меню',
    uk: 'Меню',
    en: 'Menu',
  },
  smthGoneWrong: {
    ru: 'Что-то пошло не так...',
    uk: 'Щось пішло не так...',
    en: 'Something went wrong...',
  },
  oops: {
    ru: 'Ой...',
    uk: 'Ой...',
    en: 'Oooops...',
  },
  pcs: {
    ru: 'шт',
    uk: 'шт',
    en: 'pcs',
  },
  ml: {
    ru: 'мл',
    uk: 'мл',
    en: 'ml',
  },
  g: {
    ru: 'г',
    uk: 'г',
    en: 'g',
  },
  oz: {
    ru: 'унция',
    uk: 'унція',
    en: 'oz',
  },
  ozTwo: {
    ru: 'унции',
    uk: 'унції',
    en: 'oz',
  },
  ozFive: {
    ru: 'унций',
    uk: 'унцій',
    en: 'oz',
  },
  lb: {
    ru: 'фунт',
    uk: 'фунт',
    en: 'lb',
  },
  lbTwo: {
    ru: 'фунта',
    uk: 'фунти',
    en: 'lb',
  },
  lbFive: {
    ru: 'фунтов',
    uk: 'фунтів',
    en: 'lb',
  },
  glass: {
    ru: 'бокал',
    uk: 'келих',
    en: 'glass',
  },
  glassTwo: {
    ru: 'бокала',
    uk: 'келихи',
    en: 'glasses',
  },
  glassFive: {
    ru: 'бокалов',
    uk: 'келихів',
    en: 'glasses',
  },
  bottle: {
    ru: 'бутылка',
    uk: 'пляшка',
    en: 'bottle',
  },
  bottleTwo: {
    ru: 'бутылки',
    uk: 'пляшки',
    en: 'bottles',
  },
  bottleFive: {
    ru: 'бутылок',
    uk: 'пляшкок',
    en: 'bottles',
  },
  kcal: {
    ru: 'ккал',
    uk: 'ккал',
    en: 'kcal',
  },
  looksLikeThisPageDoesntExist: {
    ru: 'Похоже, эта страница не существует',
    uk: 'Схоже, ця сторінка не існує',
    en: "Looks like this page doesn't exist",
  },
  leaveYourFeedbackAboutOurRestaurant: {
    ru: 'Mr. Tomato - Оставь свой отзыв о нашем ресторане',
    uk: 'Mr. Tomato - Залиш свій відгук про наш ресторан',
    en: 'Mr. Tomato - Leave your feedback about our restaurant',
  },
  waitMoment: {
    ru: 'Одну секунду..',
    uk: 'Одну мить..',
    en: 'Wait a moment..',
  },
  photo: {
    ru: 'Фото',
    uk: 'Фото',
    en: 'Photo',
  },
  executionLog: {
    ru: 'Журнал выполнения',
    uk: 'Журнал виконання',
    en: 'Execution log',
  },
  dishForYou: {
    ru: 'Блюдо для вас',
    uk: 'Страва для вас',
    en: 'Dish for you',
  },
  swipe: {
    ru: 'Смахните карточку влево или вправо',
    uk: 'Змахніть картку вліво чи вправо',
    en: 'Swipe to left or right',
  },
  tryAgain: {
    ru: 'Попробовать еще',
    uk: 'Спробувати ще',
    en: 'Try again',
  },
  otherRecommendations: {
    ru: 'Другие варианты',
    uk: 'Інші варіанти',
    en: 'Other recommendations',
  },
  additionalInfo: {
    ru: 'Дополнительная информация',
    uk: 'Додаткова інформація',
    en: 'Additional informations',
  },
  alcoholLevel: {
    ru: 'Процент алкоголя',
    uk: 'Відсоток алкоголю',
    en: 'Alcohol level',
  },
  beerStyle: {
    ru: 'Стиль пива',
    uk: 'Стиль пива',
    en: 'Beer style',
  },
  abv: {
    ru: 'ABV (Крепость)',
    uk: 'ABV (Міцність)',
    en: 'ABV (Alcohol by volume)',
  },
  ibu: {
    ru: 'IBU (Горечь)',
    uk: 'IBU (Гіркота)',
    en: 'IBU (International bitterness unit)',
  },
  gravity: {
    ru: 'Плотность',
    uk: 'Щільність',
    en: 'Gravity',
  },
  cocktailType: {
    ru: 'Тип коктейля',
    uk: 'Тип коктейля',
    en: 'Cocktail type',
  },
  cocktailTimeType: {
    ru: 'Тип по времени',
    uk: 'Тип по часу',
    en: 'Time type',
  },
  hotCoctail: {
    ru: 'Горячий',
    uk: 'Гарячий',
    en: 'Hot',
  },
  short: {
    ru: 'Короткий',
    uk: 'Короткий',
    en: 'Short',
  },
  shot: {
    ru: 'Шот',
    uk: 'Шот',
    en: 'Shot',
  },
  long: {
    ru: 'Длинный',
    uk: 'Довгий',
    en: 'Long',
  },
  aperitif: {
    ru: 'Аперитив',
    uk: 'Аперитив',
    en: 'Aperitif',
  },
  digestif: {
    ru: 'Дижестив',
    uk: 'Дигестив',
    en: 'Digestif',
  },
  anyTime: {
    ru: 'Любое время',
    uk: 'Будь-який час',
    en: 'Any time',
  },
  ale: {
    ru: 'Эль',
    uk: 'Ель',
    en: 'Ale',
  },
  lager: {
    ru: 'Лагер',
    uk: 'Лагер',
    en: 'Lager',
  },
  mixed: {
    ru: 'Смешанное',
    uk: 'Змішане',
    en: 'Mixed',
  },
  colorType: {
    ru: 'Тип по цвету',
    uk: 'Тип за кольором',
    en: 'Color type',
  },
  carbonatedType: {
    ru: 'Тип по наличию углекислоты',
    uk: 'Тип за наявністю вуглекислоти',
    en: 'Carbonation type',
  },
  sugarType: {
    ru: 'Тип по содержанию сахара',
    uk: 'Тип за вмістом цукру',
    en: 'Sugar type',
  },
  productionYear: {
    ru: 'Год производства',
    uk: 'Рік виробництва',
    en: 'Production Year',
  },
  dry: {
    ru: 'Сухое',
    uk: 'Сухе',
    en: 'Dry',
  },
  offDry: {
    ru: 'Полусухое',
    uk: 'Напівсухе',
    en: 'Off-dry',
  },
  semiSweet: {
    ru: 'Полусладкое',
    uk: 'Напівсолодке',
    en: 'Semi-sweet',
  },
  sweet: {
    ru: 'Сладкое',
    uk: 'Солодке',
    en: 'Sweet',
  },
  red: {
    ru: 'Красное',
    uk: 'Червоне',
    en: 'Red',
  },
  white: {
    ru: 'Белое',
    uk: 'Біле',
    en: 'White',
  },
  rose: {
    ru: 'Розовое',
    uk: 'Рожеве',
    en: 'Rose',
  },
  orange: {
    ru: 'Оранжевое',
    uk: 'Помаранчеве',
    en: 'Orange',
  },
  quiet: {
    ru: 'Тихое',
    uk: 'Тихе',
    en: 'Still',
  },
  sparkling: {
    ru: 'Игристое',
    uk: 'Iгристе',
    en: 'Sparkling',
  },
  region: {
    ru: 'Регион',
    uk: 'Регіон',
    en: 'Region',
  },
  country: {
    ru: 'Страна',
    uk: 'Країна',
    en: 'Country',
  },
}
