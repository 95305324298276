import {observable, action, computed, decorate} from "mobx";
import {MenuItemAttributes} from "./store";

import tomatoHurry from '../assets/img/tomato-hurry.svg';
import tomatoLight from '../assets/img/tomato-light.svg';
import tomatoAllergie from '../assets/img/tomato-allergie.svg';
import tomatoSpicy from '../assets/img/tomato-spicy.svg';
import tomatoVegetarian from '../assets/img/tomato-vegetarian.svg';

const LIGHT_DISH_CALORIES_LIMIT = 300;
const FAST_DISH_COOKING_TIME_LIMIT = 10;

export const SurveyQuestionType = {
	YesNo: 1,
	Multiselect: 2
}

const getSuggestionsByAnswers = (menuCategories, answers) => {
	
	return menuCategories.reduce((acc, category) => {
		const menuItems = category.menuItems.filter(mi => {
			return (!answers.inAHurry || (answers.inAHurry && mi.cookingTime <= FAST_DISH_COOKING_TIME_LIMIT))
			&& (!answers.isLightDish || (answers.isLightDish && mi.sizes.some(size => size.calories <= LIGHT_DISH_CALORIES_LIMIT) ))
			&& (!answers.hasAllergie || (answers.hasAllergie && !mi.menuItemIngredients.some(ingredient => answers.excludeIngredients.some(i => i === ingredient.id)) ))
			&& ((!answers.onlySpicyDish && !mi.menuItemAttributes.some(attr => attr.id === MenuItemAttributes.Hot)) || (answers.onlySpicyDish && mi.menuItemAttributes.some(attr => attr.id === MenuItemAttributes.Hot) ))
			&& ((!answers.onlyVegeterianDish && !mi.menuItemAttributes.some(attr => attr.id === MenuItemAttributes.Vegetarian)) || (answers.onlyVegeterianDish && mi.menuItemAttributes.some(attr => attr.id === MenuItemAttributes.Vegetarian)))
		});
		return [...acc, ...menuItems.slice(0,2)]; 
	}, []);
}

class SurveyStore {

    constructor(store) {
        this.store = store;
	}

	isCompleted = false;
	activeSuggestionIndex = 0;
	activeQuestionIndex = 0;

	answers = {
		inAHurry: false,
		isLightDish: false,
		hasAllergie: false,
		excludeIngredients: [],
		onlySpicyDish: false,
		onlyVegeterianDish: false
	}

	setQuestionAnswer = (answer) => {
		this.answers[this.questions[this.activeQuestionIndex].responseField] = answer !== null ? answer : this.answers[this.questions[this.activeQuestionIndex].responseField];
		if(this.activeQuestionIndex === this.questions.length - 1) {
			this.isCompleted = true;
			this.activeSuggestionIndex = 0;
		} else {
			this.setQuestionActiveIndex(this.activeQuestionIndex + 1)
		}
	}

	setAnswers = (answers) => {
		if (answers) {
			this.answers = {...this.answers, ...answers};
			this.isCompleted = true;
		} else {
			this.answers = {
				inAHurry: false,
				isLightDish: false,
				hasAllergie: false,
				excludeIngredients: [],
				onlySpicyDish: false,
				onlyVegeterianDish: false
			};
			this.isCompleted = false;
			this.activeQuestionIndex = 0;
		}
	}

	setSuggestionsActiveIndex = (index) => {
		this.activeSuggestionIndex = index;
	}

	setQuestionActiveIndex = (index) => {
		this.activeQuestionIndex = index;
	}
	
	get questions() {
		return [
			{
				text: this.store.getLocalized('areYouInAHurry'),
				responseField: 'inAHurry',
				image: tomatoHurry,
				type: SurveyQuestionType.YesNo
			},
			{
				text: this.store.getLocalized('doYouWantLightDish'),
				responseField: 'isLightDish',
				image: tomatoLight,
				type: SurveyQuestionType.YesNo
			},
			...(this.store.reataurantAlegens.length ? [
				{
					text: this.store.getLocalized('doYouHaveAllergie'),
					responseField: 'hasAllergie',
					image: tomatoAllergie,
					type: SurveyQuestionType.YesNo
				}
			] : []),
			...(this.answers.hasAllergie ? [
				{
					text: this.store.getLocalized('pleaseSelectAllergens'),
					responseField: 'excludeIngredients',
					options: this.store.reataurantAlegens,
					type: SurveyQuestionType.Multiselect
				}
			]: []),
			...(this.store.reataurantHasHotDishes ? [
				{
					text: this.store.getLocalized('doYouWantSpicyDish'),
					responseField: 'onlySpicyDish',
					image: tomatoSpicy,
					type: SurveyQuestionType.YesNo
				}
			]: []),
			...(this.store.reataurantHasVegetarianDishes ? [
				{
					text: this.store.getLocalized('doYouWantVegetarianDish'),
					responseField: 'onlyVegeterianDish',
					image: tomatoVegetarian,
					type: SurveyQuestionType.YesNo
				}
			]: [])
			
		];
	}	

	get suggestions() {
		let items = getSuggestionsByAnswers(this.store.menuCategories, this.answers);
		let note = null;
		if(!items.length) {
			['inAHurry', 'isLightDish', 'onlySpicyDish'].every((key) => {

				if(this.answers[key]) {
					const possibleItems = getSuggestionsByAnswers(this.store.menuCategories, {...this.answers, [key]: false});
					if(possibleItems.length) {
						note = this.store.getLocalized({inAHurry: 'notInAHurry', isLightDish: 'notLightDish', onlySpicyDish: 'notSpicyDish'}[key]);
						items = possibleItems;
						return false;
					}
				}
				return true;
			});
		}
		return {
			items,
			note
		};
	}
}

decorate(SurveyStore, {
    isCompleted: observable,
	answers: observable,
	activeSuggestionIndex: observable,
	activeQuestionIndex: observable,
	setAnswers: action,
	setQuestionAnswer: action,
	setSuggestionsActiveIndex: action,
	setQuestionActiveIndex: action,
    questions: computed,
    suggestions: computed,
});

export default SurveyStore;
