import React from 'react';
import {inject, observer} from "mobx-react";
import Header from "../Header/Header";
import Camera from "../Camera/Camera";
import './MakePhoto.css'

const MakePhoto = ({store: {getLocalized}, nextPage, imgSrc, setImgSrc, handleSnackbarOpen}) => {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = imgSrc ? urlCreator.createObjectURL(imgSrc) : null;
    const image = imgSrc ? (
        <div className="answer-container background-primary upload-container">
            <img src={imageUrl} alt="restaurant-receipt" className='receipt-photo'/>
        </div>
    ) : (
        <div className="camera-container">
            <Camera
                setImgSrc={setImgSrc}
                handleSnackbarOpen={handleSnackbarOpen}
            />
        </div>
    );

    const onReject = () => {
        setImgSrc(null);
    };


    return (
        <>
            <Header>
                {getLocalized('takePhotoOf')}
                 <br/> {getLocalized('yourCheck')}
            </Header>
            { image }
            {
                imgSrc &&
            <>
                <button
                    disabled={!imgSrc}
                    onClick={nextPage}
                    className='btn btn-primary btn-rounded mb-4 upload-button'
                >
                    {getLocalized('next')}
                </button>
                <button
                    className='reject'
                    onClick={onReject}
                >{getLocalized('makeAnotherPhoto')}
                </button>
            </>
            }
        </>
    )
};

export default inject('store')(observer(MakePhoto));
