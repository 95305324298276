import React from 'react'
import logo from "../../assets/img/logo.svg";
import './Header.css'


const Header = (props) => {
    return (
        <header className='header'>
            <img src={logo} className='logo' alt=""/>
            <div className='invitation'>
                {props.children}
            </div>
        </header>
    )
};

export default Header;
