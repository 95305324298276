import React, { cloneElement, useState, useEffect, useRef } from 'react'
import { DIRECTIONS } from './utils'
import './SwipeableCards.css';

const SwipeableCards = ({activeIndex = 0, className, children, onEnd}) => {
	const [index, setActiveIndex] = useState(activeIndex);
	const [containerSize, setContainerSize] = useState({ x: 0, y: 0 });

	const containerRef = useRef();

	useEffect(() => {
		setActiveIndex(activeIndex);
	}, [activeIndex]);

	useEffect(() => {
		setSize();
	}, [containerRef])

	const removeCard = () => {
		if (children.length === (index + 1) && onEnd) onEnd()

		setActiveIndex(index + 1);
	}

	const setSize = () => {
		const container = containerRef.current;
		setContainerSize({
		  x: container.offsetWidth,
		  y: container.offsetHeight
		});
	}

	if (!containerSize.x || !containerSize.y) return  <div ref={containerRef} className={className} />

    const _cards = children.reduce((memo, c, i) => {
	  if (index > i) return memo;

      const props = {
        key: i,
		containerSize,
		index: i - index,
        zIndex: children.length - i,
        ...DIRECTIONS.reduce((m, d) => 
          ({ ...m, [`onOutScreen${d}`]: removeCard }), {}),
        active: index === i
	  }
      return [ cloneElement(c, props), ...memo ]
    }, []);

	return (
		<div ref={containerRef} className={['swipeable-cards', className].join(' ')}>
			<div id='cards'>
			{_cards}
			</div>
		</div>
	);
}

export default SwipeableCards;