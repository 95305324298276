import React, { useState, useRef, useEffect } from 'react';
import { translate3d } from './utils';

const SimpleCard = ({containerSize, className, index, zIndex, style, children}) => {
	const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		setPosition();
	}, [])

	const ref = useRef();

	const setPosition = () => {
		const card = ref.current;
		setInitialPosition({
			x: Math.round((containerSize.x - card.offsetWidth) / 2),
			y: Math.round((containerSize.y - card.offsetHeight) / 2)
		});
	}

	const styles = {
		...translate3d(initialPosition.x, initialPosition.y, index),
		zIndex: zIndex,
		...style
	}
  
	return (
		<div ref={ref} style={styles} className={`card ${className ? className : ''}`}>
			{children}
		</div>
	);
}

export default SimpleCard;