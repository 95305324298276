import React from "react";
import { inject, observer } from "mobx-react";
import styled from "@emotion/styled";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import RestaurantHeader from "../../components/RestaurantHeader/RestaurantHeader";
import ImagesPreviewModal, { ALLOWED_VIDEO_TYPES } from "../../components/ImagesPreviewModal";
import stepPlaceholder from "../../assets/img/icon-checklist-step-placeholder.svg";
import videoPlaceholder from "../../assets/img/video-placeholder.png";
import completeIcon from "../../assets/img/icon-complete.svg";
import { QrCodePageTheme } from "../../store/store";

const ruFormat = "DD MMMM YYYY k:mm";
const enFormat = "MMM/DD/YYYY  hh:mm A"; 

const SpotChecker = ({
  store: {
    spotCheckers,
    isModalVisible,
    getLocalized,
    language,
    restaurant: { qrCodePageTheme },
  },
}) => {
  const id = +new URL(window.location.href).searchParams.get("id");
  const {steps, name, sessions} = spotCheckers.find(s => s.id === id) || {};
  const stepsList =
    steps &&
    steps.map((step, index) => {
      const { name: stepName, description, id, mediaFile } = step;
      let fileName = mediaFile ? mediaFile.fileName : "";
      let mimeType = mediaFile ? mediaFile.mimeType : "";
      const children = (
        <StepWrapper>
          <StepImageWrapper>
            {fileName ? (
              <OpenPreviewImagesButton images={[{ fileName: fileName, mimeType }]}>
                {() =>
                  ALLOWED_VIDEO_TYPES.includes(mimeType) ? (
                    <img
                      style={{
                        width: 48,
                        height: 48,
                        borderRadius: 8,
                        objectFit: "cover",
                      }}
                      src={videoPlaceholder}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{
                        width: 48,
                        height: 48,
                        borderRadius: 8,
                        objectFit: "cover",
                      }}
                      src={`${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${fileName}&width=48&height=48`}
                      alt=""
                    />
                  )
                }
              </OpenPreviewImagesButton>
            ) : (
              <img src={stepPlaceholder} alt="" />
            )}
          </StepImageWrapper>
          <StepInformation>
            <StepName>{stepName}</StepName>
            <StepDescription theme={qrCodePageTheme}>{description}</StepDescription>
          </StepInformation>
        </StepWrapper>
      );
      return (
        <Complete key={id} stepNumber={index + 1}>
          {children}
        </Complete>
      );
    });

  const format = language === "ru" || language === "uk" ? ruFormat : enFormat;

  const capitalize = (stringValue) => stringValue[0].toUpperCase() + stringValue.substring(1);

  const sessionsList =
    sessions &&
    sessions.map((session) => {
      const {
        executor: { firstName, lastName },
        timestampUnixUtcMs,
        sessionId,
      } = session;
      const children = (
        <SessionWrapper>
          {capitalize(firstName)}
          &nbsp;
          {capitalize(lastName[0])}. &nbsp;
          {moment(timestampUnixUtcMs).local().locale(language).format(format)}
        </SessionWrapper>
      );
      return (
        <Complete key={sessionId} paddingTop="12px">
          {children}
        </Complete>
      );
    });

  return (
    <div className="page p-4">
      <RestaurantHeader />
      <h4 className="mb-3">{name}</h4>
      {stepsList}
      {sessions && !!sessions.length && (
        <ExecutionWrapper>
          <ExecutionLabel>{getLocalized("executionLog")}</ExecutionLabel>
          {sessionsList}
        </ExecutionWrapper>
      )}
      {isModalVisible && <ImagesPreviewModal />}
    </div>
  );
};

export default inject("store")(observer(SpotChecker));

const OpenPreviewImagesButton = inject("store")(
  observer(({ children, images, store, name }) => {
    return <ButtonWithoutStyles onClick={store.openModal(images, name)}>{children()}</ButtonWithoutStyles>;
  })
);

const Complete = ({ stepNumber, paddingTop, children }) => {
  return (
    <Wrapper paddingTop={paddingTop}>
      <CompleteSign>{stepNumber || <img src={completeIcon} alt="" />}</CompleteSign>
      {children || null}
    </Wrapper>
  );
};

export const ButtonWithoutStyles = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  text-align: center;
  padding: 0;

  :focus,
  :hover {
    outline: none;
    cursor: pointer;
  }
`;

const StepImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
`;

const StepDescription = styled.p`
  color: ${(theme) => (theme === QrCodePageTheme.Dark ? "#50565B" : "#9FA5AD")};
  overflow: hidden;
`;

const StepWrapper = styled.div`
  display: flex;
  width: 95%;
  min-height: 48px;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(theme) => (theme === QrCodePageTheme.Dark ? "#F2F4F8" : "#697077")};
`;

const SessionWrapper = styled.div`
  display: flex;
  width: 95%;
  min-height: 30px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(theme) => (theme === QrCodePageTheme.Dark ? "#F2F4F8" : "#697077")};
  font-family: Rubik;
  font-size: 14px;
  line-height: 24px;
`;

const ExecutionWrapper = styled.div`
  margin-top: -5px;
`;

const ExecutionLabel = styled.h4`
  font-family: Rubik;
  padding-top: 16px;
  padding-bottom: 8px;
`;

const StepInformation = styled.div`
  margin-left: 20px;
`;

const StepName = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  padding-top: ${({ paddingTop }) => paddingTop || "0px"};
`;

const CompleteSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #2ecc71;
  margin-right: 12px;
  color: #fff;
  font-family: Rubik;
  font-size: 0.8rem;
  font-weight: 500;
`;
