import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import grey from "@material-ui/core/colors/grey";
import { green } from "@material-ui/core/colors";

export const theme = createMuiTheme({
    palette: {
        primary: grey,
    },
    typography: {useNextVariants: true}
});

export const greenTheme = createMuiTheme({
    palette: {
        primary: green,
    },
    typography: {useNextVariants: true}
});