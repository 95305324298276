import React, { useState } from 'react';
import {inject, observer} from "mobx-react";
import Header from "../Header/Header";
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from "../../assets/styles";
import './Contacts.css';



const useStyles = makeStyles(theme => ({
    formContainer: {
        width: '90%',
        margin: 'auto',
    },
    textField: {
        width: '100%',
        color: '#9FA5AD'
    },
    checkBox: {
        marginLeft: 0,
        marginTop: theme.spacing(3),
    },
}));




const styles = {
    label: {
       fontSize: 12,
       marginTop: 25,
    },
};
const Contacts = ({store: {getLocalized}, handleCredentials, handleSend, credentials: {name, phone}}) => {
    const [checked, setChecked] = useState(false);

    const classes = useStyles();

    const handleCheckbox = (e) => setChecked(e.target.checked);

    const disabled = !checked || !name || !phone;


    return (
        <>
        <Header>
            {getLocalized('pleaseLeaveYour')}<br/>{getLocalized('contactDetails')}
        </Header>
            <div className="answer-container  background-primary">
                <div className='question-container'>
                    <MuiThemeProvider theme={theme}>
                    <form noValidate autoComplete="off">
                        <div className={classes.formContainer}>
                            <TextField
                                className={classes.textField}
                                label={getLocalized('name')}
                                name="name"
                                onChange={handleCredentials}
                                value={name}
                                margin="normal"
                                InputProps={{
                                    className: classes.textField,
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                label={getLocalized('phoneNumber')}
                                name="phone"
                                type="tel"
                                onChange={handleCredentials}
                                value={phone}
                                margin="normal"
                                InputProps={{
                                    className: classes.textField,
                                }}
                            />

                            <FormControlLabel
                                classes={classes.label}
                                control =
                            {<Checkbox
                                onChange={handleCheckbox}
                                className={classes.checkBox}
                                color="default"
                                value={checked}
                            />}
                                label=
                            {<Typography
                                style={styles.label}>
                                {getLocalized('iConsentMyPersonalDataUsage')}
                            </Typography>}
                            />
                        </div>
                    </form>
                    </MuiThemeProvider>
                    {/*<div className='link-button-container'>*/}
                    {/*    <a href="./conditions.html" target="_blank" className='link-button' >Умови лотереї</a>*/}
                    {/*    <a href="./agreement.html" target="_blank" className='link-button'>Угода користувача</a>*/}
                    {/*</div>*/}
                    <button
                        disabled={disabled}
                        onClick={handleSend}
                        className='btn btn-primary btn-rounded mb-4 contact-button'
                    >
                        {getLocalized('takePart')}
                    </button>
                </div>
            </div>
         </>
    )
};

export default inject('store')(observer(Contacts));
