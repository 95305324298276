import React from "react";
import { inject, observer } from "mobx-react";
import "./MenuItemDescription.css";

const MenuItemDescription = ({ store: { getLocalized }, description }) => {

  return description && !!description.length ? (
    <>
      <div className="label-field background-secondary p-3 mx-n3 mb-3">{getLocalized("descriptionCapital")}</div>
      <div className="description text-gray-70 mb-4">{description}</div>
    </>
  ) : null;
};

export default inject("store")(observer(MenuItemDescription));
