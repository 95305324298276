import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import "./Wine.css";
import MenuItemPairs from "../../components/MenuItemPairs/MenuItemPairs";
import MenuItemIngredients from "../../components/MenuItemIngredients/MenuItemIngredients";
import MenuItemDescription from "../../components/MenuItemDescription/MenuItemDescription";
import MenuItemSizes from "../../components/MenuItemSizes/MenuItemSizes";
import MenuItemHeader from "../../components/MenuItemHeader/MenuItemHeader";
import WineItemAttributes from "../../components/WineItemAttributes/WineItemAttributes";
import MenuItemAllergens from "../../components/MenuItemAllergens/MenuItemAllergens";

const Wine = ({
  store: {
    menuCategories,
    wineCategories,
    restaurant: { currency },
  },
  history,
}) => {
  const params = new URL(window.location.href).searchParams;
  const categoryId = +params.get("categoryId");
  const itemId = +params.get("itemId");

  const ref = useRef(null);
  const item = (wineCategories.find(c => c.id === categoryId) || {alcoholicItems: []}).alcoholicItems.find(mi => mi.id === itemId) || {};

  const { images, name, wineCategory, sizes, description, menuItemsPairs, ingredients, ...attributes } = item;

  const fileName = images && images[0] && images[0].fileName;

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [itemId, categoryId]);

  const handlePair = (categoryId, id, type) => {
    const items = type === 2 ? 
      (menuCategories.find(c => c.id === categoryId) || {menuItems: []}):
      (wineCategories.find(c => c.id === categoryId) || {wineItems: []});
    if (items.find(i => i.id === id)) {
      history.push(`/${type === 2 ? 'dish' : 'wine'}?categoryId=${categoryId}&itemId=${id}`)
    }
  }

  return (
    <div className="page" ref={ref}>
      <MenuItemHeader name={name} fileName={fileName} category={wineCategory} />
      <div className="p-3">
        <MenuItemSizes sizes={sizes} currency={currency} type="wine"/>
        <WineItemAttributes attributes={attributes}/>
        <MenuItemDescription description={description} />
        <MenuItemIngredients ingredients={ingredients} isCocktail={attributes.type === 3} />
        <MenuItemAllergens ingredients={ingredients} />
        <MenuItemPairs items={menuItemsPairs} onClick={handlePair} />
      </div>
    </div>
  );
};

export default inject("store")(observer(Wine));
