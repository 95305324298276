import React from 'react';
import { withRouter } from 'react-router-dom';
import arrow from '../assets/img/arrow.svg';
import arrowInverse from '../assets/img/arrow-white.svg';


const BackButton = ({history, inverse, backdrop}) => {
    return (
        <button className={`btn btn-link btn-back ${backdrop ? 'background-secondary': ''}`} onClick={() => history.goBack()}>
            <img
                src={!inverse ? arrow: arrowInverse}
                alt=""
            />
        </button>
    )
};

export default withRouter(BackButton);
