import React from 'react';
import {inject, observer} from "mobx-react";
import tomato from '../../assets/img/tomato-team.png'
import Header from "../Header/Header";
import './Final.css'

const Final = ({store: {getLocalized}}) => {
    return (
        <div className='final-container'>
            <Header/>
            <div className="final-wrapper background-primary">
                <img src={tomato} alt=""/>
                <p className='gratitude'>
                    {getLocalized('thanksForYourFeedback')}
                    <br/>
                    {getLocalized('weWillContactYouSoon')}
                </p>
                <p className='team'>
                    {getLocalized('mrTomatoTeam')}
                </p>
            </div>
        </div>
    )
};

export default inject('store')(observer(Final))



