import {observable, action, computed, decorate, } from "mobx";
import {locales} from "../locale";
import SurveyStore from "./surveyStore";

export const MenuItemAttributes = {
	Vegetarian: 3,
	Hot: 4,
	Bestseller: 6,
}

export const QrCodePageTheme = {
	Dark: 1, 
	Light: 2
}

class Store {

    constructor() {
        this.surveyStore = new SurveyStore(this);
        this.startLoad();
    }

    language = (navigator.language || '').split('-')[0];
    restaurant = {};
    menuCategories = [];
    wineCategories = [];
    spotCheckers = [];
    images = [];
    name = '';
    isModalVisible = false;
    currentImageIndex = 0;
    snackbarOpen = false;
    linkError = false;

    startLoad() {
        let restaurantId = new URL(window.location.href).searchParams.get('restaurantId');
        if (!restaurantId) {
            if (localStorage.getItem('restaurantId')) {
                restaurantId = localStorage.getItem('restaurantId');
            } else {
                this.linkError = true;
            }
        }
        localStorage.setItem('restaurantId', restaurantId);
        fetch(
            `${process.env.REACT_APP_API}/api/Public/GetRestaurantInfo?restaurantId=${restaurantId}`, {
                headers: {
                    language: this.language
                }
            })
            .then(response => response.json())
            .then(publicInfo => {
                if (!publicInfo.restaurant) {
                    this.linkError = true;
                } else {
                    this.restaurant = publicInfo.restaurant;
                    this.menuCategories = publicInfo.menuCategories;
                    this.wineCategories = publicInfo.wineCategories || [];
                    this.spotCheckers = publicInfo.checklists;
                }
            })
            .catch(() => this.setSnackbarOpen(true))
    }

    setSnackbarOpen = (value) => {
        this.snackbarOpen = value;
    };

    openModal = (images, name) => () => {
        this.images = images;
        this.isModalVisible = true;
        this.name = name;
    };

    toggleModal = () => {
        this.isModalVisible = !this.isModalVisible;
    };

    getLocalized = (key) => {
        if (locales[key]) {
            return locales[key][this.language];
        }
        return key;
    };

    setLanguage = (language) => {
        this.language = language;
        this.startLoad();
    };

    showNextImage = () => {
        const nextIndex = this.currentImageIndex + 1;
        const nextImage = this.images[nextIndex];
        this.setCurrentImageIndex(nextImage ? nextIndex : 0);
    };

    showPreviousImage = () => {
        const index =
            this.currentImageIndex === 0
                ? this.images.length - 1
                : this.currentImageIndex - 1;
        this.setCurrentImageIndex(index);
    };

    setCurrentImageIndex = (index) => {
        this.currentImageIndex = index;
    };

    get reataurantAlegens() {
        const alergensMap = {};
		this.menuCategories.forEach(category => {
			category.menuItems.forEach(mItem => {
				mItem.menuItemIngredients.forEach(miIngredient => {
					if(miIngredient.isAllergen) {
						alergensMap[miIngredient.id] = miIngredient;
					}
				})
			})
        });
		return Object.values(alergensMap);
    }

    get reataurantHasHotDishes() {
		return this.menuCategories.reduce((flag, category) => {
			return flag || category.menuItems.some(mItem => 
				mItem.menuItemAttributes.some(attr => attr => attr.id === MenuItemAttributes.Hot)
			)
		}, false);
    }

    get reataurantHasVegetarianDishes() {
		return this.menuCategories.reduce((flag, category) => {
			return flag || category.menuItems.some(mItem => 
				mItem.menuItemAttributes.some(attr => attr => attr.id === MenuItemAttributes.Vegetarian)
			)
		}, false);
    }
}

decorate(Store, {
    language: observable,
    restaurant: observable,
    menuCategories: observable,
    menuCategoryId: observable,
    menuItemId: observable,
    spotCheckerId: observable,
    isModalVisible: observable,
    currentImageIndex: observable,
    name: observable,
    images: observable,
    snackbarOpen: observable,
    spotCheckers: observable,
    linkError: observable,
    getLocalized: action,
    setLanguage: action,
    setCurrentImageIndex: action,
    setSnackbarOpen: action,
    setLikError: action,
    reataurantAlegens: computed,
    reataurantHasHotDishes: computed,
    reataurantHasVegetarianDishes: computed,
});

export default new Store();
