import React from "react";
import stub from "../../assets/img/menu-item-stub.svg";
import BackButton from "../BackButton";
import Img from "../Image";
import "./MenuItemHeader.css";

const MenuItemHeader = ({name, fileName, category }) => {
  return (
    <div className="mi-header d-flex flex-column align-items-stretch">
      <div className="back-holder">
        <BackButton backdrop />
      </div>
      <div className={`image-wrapper ${fileName ? "" : "small"}`} style={{ backgroundImage: `url(${stub})` }} >
        {fileName && (
          <Img
            className="image-cover"
            src={`${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${fileName}&width=500&height=500`}
            alt=""
          />
        )}
      </div>
      <div className="p-3">
        <h3>{name}</h3>
        <p className="menu-category">{category && category.name}</p>
      </div>
    </div>
  );
};

export default MenuItemHeader;
