import React from 'react';
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";
import SurpriseMeButton from "../../components/SurpriseMeButton/SurpriseMeButton";
import dishIcon from "../../assets/img/icon-dish.svg";

const MealSurveyFinished = ({store: {getLocalized, menuCategories}, suggestions}) => {
    const hasMenuItems = menuCategories.some(item => !!item.menuItems.length);
	return (
		<div className="survey-finished">
			<div className="d-flex flex-column align-items-center mb-4">
				<img className="mb-3" src={dishIcon} alt=""/>
				<p className="survey-finished-description text-center text-gray-80 px-4">{suggestions.length ? getLocalized('noDishesToSuggestMore') : getLocalized('noDishesToSuggest')}</p>
			</div>
			<div className="btn-wrapper pt-4">
				{hasMenuItems && (
					<Link className='mb-3' to="/menu">
						<div className='btn btn-primary'>
							{getLocalized('viewMenu')}
						</div>
					</Link>
				)}
				{hasMenuItems && (<SurpriseMeButton />)}
			</div>
		</div>
	);
};

export default inject('store')(observer(MealSurveyFinished));
