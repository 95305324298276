import React, { useState, useEffect } from 'react';
import {inject, observer} from "mobx-react";
import Circle from "./Circle/Circle";
import MoneyBag from "../MoneyBag/MoneyBag";
import Header from "../Header/Header";
import {constraints, numbers} from '../../assets/constants'
import Slider from '@material-ui/core/Slider';
import {makeStyles, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {greenTheme} from "../../assets/styles";
import CSSTransition from "react-transition-group/CSSTransition";
import './Advise.css'


const desktop = window.innerWidth > 450;

const Advise = ({store: {getLocalized},setEstimated, goToPage, setAvailable}) => {

    const [isActive, setIsActive] = useState(Array.from({length: 10}, v => false));

    const [disabled, setDisabled] = useState(true);

    const [label, setLabel] = useState(getLocalized('next'));

    const [start, setStart] = useState(false);

    useEffect(() => {
        setStart(true);
        return () => setStart(false);
    }, []);

    const onSelect = (index) => {
        setIsActive(Array.from({length: 11}, (value, i) => index === i ));
        setEstimated({questionId: 4, answerValue: index + ''});
        setDisabled(false)
    };

    const toNextPage = () => {
        setLabel(getLocalized('waitMoment'));
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia(constraints)
                .then(() => {
                    setAvailable(true);
                })
                .finally(() => goToPage(5))
        } else goToPage(5)
    };

    const circles = numbers.map((item, index) =>
        <Circle
            key={item.number}
            active={isActive[index]}
            index={index}
            item={item}
            onSelect={onSelect}
        />
    );

    const classes = useStyles();

    const handleSlider = (e, value) => {
        setEstimated({questionId: 4, answerValue: value + ''});
        setDisabled(false)
    };

    if (desktop) {
        return (
            <>
                <Header>
                    {getLocalized('pleaseLeaveYourFeedback')}
                    <br/>
                    {getLocalized('aboutOurRestaurant')}                    
                </Header>
                <div className="answer-container background-primary">
                    <div className='advise-container'>
                        <CSSTransition in={start} timeout={800} classNames='animate'>
                            <div>
                                <p className='advise-label'>
                                    {getLocalized('howLikelyIsItThatYouRecommendOurRestaurant')}
                                    <br/>
                                    {getLocalized('toFriendOrColleague')}
                                </p>
                                <div className='circles-container'>
                                    {circles}
                                </div>
                            </div>
                        </CSSTransition>
                        <button className='btn btn-primary btn-xlg btn-rounded mb-4 send-button' disabled={disabled} onClick={toNextPage}>{label}</button>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Header>
                    {getLocalized('pleaseLeaveYourFeedback')}
                    <br/>
                    {getLocalized('aboutOurRestaurant')}                    
                </Header>

                <div className="answer-container  background-primary">
                    <div className='advise-container'>
                        <CSSTransition in={start} timeout={800} classNames='animate'>
                            <div>
                                <p className='advise-label'>
                                    {getLocalized('howLikelyIsItThatYouRecommendOurRestaurant')}
                                    <br/>
                                    {getLocalized('toFriendOrColleague')}
                                </p>
                                <div className={classes.root}>
                                    <MuiThemeProvider theme={greenTheme}>
                                        <Slider
                                            defaultValue={3}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="auto"
                                            onChange={handleSlider}
                                            step={1}
                                            min={0}
                                            max={10}
                                        />
                                    </MuiThemeProvider>
                                </div>
                                <div className='marks text-gray-50'>
                                    <div>{getLocalized('never')}</div>
                                    <div>{getLocalized('mostLikely')}</div>
                                </div>
                            </div>
                        </CSSTransition>
                        <button className='btn btn-primary btn-xlg btn-rounded mb-4 send-button' disabled={disabled} onClick={toNextPage}>{label}</button>
                    </div>
                </div>
            </>
        )
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 250,
        marginTop: 30,
        margin: 'auto'
    },
    margin: {
        height: theme.spacing(3),
    },
}));

export default inject('store')(observer(Advise));
