import React from 'react';
import {inject, observer} from "mobx-react";
import BackButton from "../BackButton";
import "./RestaurantHeader.css";
import Img from '../Image';


const RestaurantHeader = ({store: {restaurant}, hideBack}) => {
    const {image, name} = restaurant;
    return (
        <div className='restaurant-header'>
            {!hideBack && <BackButton/>}
            {image && <div className="restaurant-logo">
                <Img
                    src={`${process.env.REACT_APP_API}/api/Images/GetPublicImage?filename=${image}&width=300&height=300`}
                    alt="logo"
                />
            </div>}
            <h1 className='restaurant-name text-gray-60'>{name}</h1>
        </div>
    )
};

export default inject('store')(observer(RestaurantHeader))
